import { Alert as LearnerblyAlert } from "@learnerbly/web-components";
import { FunctionComponent } from "react";
import posed from "react-pose";

import styles from "./styles.scss";

const Box = posed.div({
  hide: {
    right: "-20rem",
    transition: {
      duration: 300,
      ease: "easeIn",
    },
  },
  show: {
    right: "2rem",
    transition: {
      duration: 300,
      ease: "easeOut",
    },
  },
});

type AlertProps = {
  message: string;
  onClose: () => void;
  show: boolean;
  type: "error" | "success";
};

const Alert: FunctionComponent<AlertProps> = ({
  message,
  onClose,
  show,
  type,
}) => (
  <Box
    pose={show ? "show" : "hide"}
    className={styles.container}
    data-cy="alert"
  >
    <LearnerblyAlert message={message} onClose={onClose} type={type} />
  </Box>
);

export default Alert;
