import { useEffect, useState } from "react";

export const useAISearchCommand = (
  defaultValue = false,
): [ai: boolean, setAi: (value: boolean) => void] => {
  const [ai, setAi] = useState(defaultValue);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "i" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setAi((ai) => !ai);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  return [ai, setAi];
};
