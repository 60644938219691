import { graphql } from "src/__generated__";

export const INTELLIGENT_SEARCH_RESOURCES = graphql(`
  query IntelligentSearchResources($query: String!, $currency: CurrencyCode!) {
    intelligentSearchResources(query: $query, currency: $currency) {
      id
      title
      image1x1
      supplier {
        id
        name
      }
      type {
        id
        name
      }
      lowestPrice {
        amount
        currency
      }
    }
  }
`);
