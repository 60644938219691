import Auth from "@aws-amplify/auth";
import { closeIntercom } from "src/shared/util/intercom";
import { logError } from "src/shared/util/error-logging";

export const federatedSignIn = (customProvider: string): void => {
  configureAuth();
  Auth.federatedSignIn({
    customProvider,
  });
};

export const getToken = (): Promise<string> =>
  Auth.currentSession()
    .then((session) => session.getAccessToken().getJwtToken())
    .catch(
      () =>
        sessionStorage.getItem("credential") ||
        localStorage.getItem("credential") ||
        "",
    );

export const logout = (): void => {
  // this handles logout for both, the normal login and Cognito's login
  configureAuth();
  Auth.signOut()
    .then(() => {
      closeIntercom();
      sessionStorage.removeItem("credential");
      localStorage.removeItem("credential");
      window.location.href = process.env.APP_URL as string;
    })
    .catch(logError);
};

export const configureAuth = (): void => {
  Auth.configure({
    oauth: {
      domain: process.env.AWS_COGNITO_DOMAIN,
      scope: ["email", "openid"],
      redirectSignIn: `${process.env.APP_URL}/auth-callback/`,
      redirectSignOut: `${process.env.APP_URL}/logout/`,
      responseType: "code",
      options: {
        AdvancedSecurityDataCollectionFlag: true,
      },
    },
  });
};

export const initiateResetPassword = (email: string): void => {
  configureAuth();
  Auth.forgotPassword(email).catch(logError);
};

export const confirmResetPassword = (
  username: string,
  verificationCode: string,
  newPassword: string,
): Promise<string> => {
  return Auth.forgotPasswordSubmit(
    username,
    verificationCode,
    newPassword,
  ).catch((error) => {
    logError(error);
    throw error;
  });
};
