import { useEffect, useState } from "react";

export const useOpenSearchCommand = (
  defaultValue = false,
): [open: boolean, setOpen: (value: boolean) => void] => {
  const [open, setOpen] = useState(defaultValue);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  return [open, setOpen];
};
