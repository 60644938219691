import classnames from "classnames";
import { FunctionComponent, useContext } from "react";

import usePageView from "src/shared/hooks/use-page-view";
import Empty from "../empty";
import { AppContext } from "src/app-context";

import styles from "./styles.scss";
import PageMeta from "../PageMeta";

const NotFoundPage: FunctionComponent = () => {
  usePageView("404");

  const { currentUser } = useContext(AppContext);

  const classes = classnames({
    [styles.noNavigation]: !currentUser,
  });

  return (
    <div className={classes}>
      <PageMeta title="Page not found" />
      <Empty emoji="🤔">
        <p>Sorry, this page doesn&#39;t exist</p>
      </Empty>
    </div>
  );
};

export default NotFoundPage;
