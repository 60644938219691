import { algoliasearch } from "algoliasearch";
import { ResourceProvider } from "./ResourceProvider";
import { CommandResource, Currency, CurrentUser } from "../types";

const ENVIRONMENT = process.env.STAGE as string;
const RECORD_TYPE = "resources";

interface AlgoliaResource extends Omit<CommandResource, "lowestPrice"> {
  lowestPricesByContentRegionAndCurrency?: Record<
    string,
    Record<string, number>
  >;
}

export class AlgoliaResourceProvider implements ResourceProvider {
  private indexName = `${ENVIRONMENT}_${RECORD_TYPE}`;
  private searchClient;

  constructor(private currentUser: CurrentUser) {
    this.searchClient = algoliasearch(
      process.env.ALGOLIA_APP_ID || "",
      this.currentUser.algoliaSearchKey,
    );
  }

  private normaliseResources(
    resources: Array<AlgoliaResource>,
  ): Array<CommandResource> {
    return resources.map((resource) => {
      const { lowestPricesByContentRegionAndCurrency, ...data } = resource;
      const lowestPricesForRegion =
        lowestPricesByContentRegionAndCurrency?.[this.currentUser.country];

      if (!lowestPricesForRegion) {
        return {
          ...data,
          lowestPrice: {
            amount: 0,
            currency: this.currentUser.currency,
          },
        };
      }

      const mappedCurrenciesAndPrices = Object.entries(
        lowestPricesForRegion,
      ) as unknown as Array<[Currency, number]>;

      const lowestAvailablePrice = mappedCurrenciesAndPrices.reduce(
        (acc, [currency, price]) => {
          const convertedPrice = Math.ceil(
            price * this.currentUser.exchangeRates[currency],
          );

          if (!acc || acc > convertedPrice) {
            return convertedPrice;
          }

          return acc;
        },
        0,
      );

      return {
        ...data,
        lowestPrice: {
          amount: lowestAvailablePrice,
          currency: this.currentUser.currency,
        },
      };
    });
  }

  async query(query: string): Promise<Array<CommandResource>> {
    const response = await this.searchClient.searchSingleIndex<AlgoliaResource>(
      {
        indexName: this.indexName,
        searchParams: {
          query,
          hitsPerPage: 5,
          ruleContexts: ["search"],
          clickAnalytics: true,
          userToken: this.currentUser.id,
          attributesToRetrieve: [
            "id",
            "title",
            "lowestPricesByContentRegionAndCurrency",
            "type",
            "image1x1",
            "supplier",
          ],
        },
      },
    );

    return this.normaliseResources(response.hits);
  }

  abortQuery(): void {
    // No-op
  }
}
