import { createContext, FunctionComponent, useContext } from "react";

type GenericRecord = Record<string, number | string | symbol>;

export type ContextualTrackingData = Record<
  string,
  GenericRecord | number | string | symbol
>;

export enum Trigger {
  Clicked = "Clicked",
}

export type TrackingInfo = {
  origin: string;
  trigger: Trigger.Clicked;
};

export type TrackingData = TrackingInfo & {
  context: ContextualTrackingData;
};

type TrackingHandler = (trackingData: TrackingData) => void;

type ReactContext = {
  contextualTrackingData: ContextualTrackingData;
  handlers?: Array<TrackingHandler>;
};

const defaultTrackingContext: ReactContext = {
  contextualTrackingData: {},
  handlers: [],
};

export const TrackingContext = createContext(defaultTrackingContext);

type Props = {
  contextualTrackingData: ContextualTrackingData;
  handlers?: Array<TrackingHandler>;
};

export const Tracking: FunctionComponent<Props> = ({
  children,
  contextualTrackingData,
  handlers,
}) => {
  const previousTrackingContext = useContext(TrackingContext);

  const latestContextualTrackingData = {
    ...previousTrackingContext.contextualTrackingData,
    ...contextualTrackingData,
  };

  const latestHandlers = [
    ...(previousTrackingContext.handlers || []),
    ...(handlers || []),
  ];

  const latestTrackingContext = {
    contextualTrackingData: latestContextualTrackingData,
    handlers: latestHandlers,
  };

  return (
    <TrackingContext.Provider value={latestTrackingContext}>
      {children}
    </TrackingContext.Provider>
  );
};
