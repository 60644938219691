import { FunctionComponent } from "react";
import { Text } from "@learnerbly/web-components";

import usePageView from "../../hooks/use-page-view";
import PageMeta from "../PageMeta";
import styles from "./styles.scss";
import classnames from "classnames";

export const Page: FunctionComponent<{
  title: string;
  trackingTitle?: string;
  description?: string;
  showTitle?: boolean;
  wide?: boolean;
  headingCTA?: React.ReactNode;
}> = ({
  title,
  trackingTitle = title,
  description,
  children,
  showTitle = true,
  wide = false,
  headingCTA,
}) => {
  usePageView(trackingTitle);

  const containerClasses = classnames(styles.container, {
    [styles.wide]: wide,
  });

  return (
    <>
      <PageMeta title={title} description={description} />

      <div className={containerClasses}>
        {showTitle && (
          <div className={styles.heading}>
            <Text textStyle="heading">{title}</Text>
            {headingCTA}
          </div>
        )}

        {children}
      </div>
    </>
  );
};
