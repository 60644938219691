// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".w4vJHXf7uvjbxsQ1aNqu{padding:1rem 1rem 0.5rem 1rem;border-top:1px solid #b4bcce50;display:flex;justify-content:space-between}@media (max-width: 48em){.w4vJHXf7uvjbxsQ1aNqu{flex-direction:column}}\n", "",{"version":3,"sources":["webpack://./src/shared/components/search-command/components/CommandDialogFooter/styles.scss"],"names":[],"mappings":"AAEA,sBACE,6BAA8B,CAC9B,8BAA+B,CAC/B,YAAa,CACb,6BAA8B,CAC9B,yBALF,sBAMI,qBAAsB,CAEzB","sourcesContent":["@import \"src/shared/styles/variables\";\n\n.commandFooter {\n  padding: 1rem 1rem 0.5rem 1rem;\n  border-top: 1px solid #b4bcce50;\n  display: flex;\n  justify-content: space-between;\n  @media (max-width: $sm-screen) {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commandFooter": "w4vJHXf7uvjbxsQ1aNqu"
};
export default ___CSS_LOADER_EXPORT___;
