import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import ButtonLegacy from "../button-legacy";

const RequestAnythingButton: FunctionComponent = () => (
  <Link to="/request/?currentPage=1&lastPageCompleted=0">
    <ButtonLegacy data-testid="request-anything" icon={faPaperPlane} smallBlue>
      Resource not on Learnerbly?
    </ButtonLegacy>
  </Link>
);

export default RequestAnythingButton;
