import { Link, NavLink } from "react-router-dom";

import PropTypes from "prop-types";
import moment from "moment-timezone";

import { useContext } from "react";
import { AppContext } from "../../../app-context";
import { useFeature, useFeatureIsOn } from "@growthbook/growthbook-react";
import { Text } from "@learnerbly/web-components";
import { track } from "src/shared/util/segment";

import { formatCurrencyValue } from "../../util/text-display";
import UserImage from "../user-image/user-image";
import {
  hasSelfServePlaylist,
  hasJobProfile,
} from "src/shared/util/user-constraints";
import styles from "./styles.scss";

const PageLink = ({ to, children, onClick }) => (
  <NavLink className={styles.link} to={to} onClick={onClick} exact>
    <div className={styles.linktext}>{children}</div>
  </NavLink>
);

PageLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

const ProfileNavigation = () => {
  const { currentUser, showNavigation, setShowNavigation } =
    useContext(AppContext);

  const hasLearningPathways = useFeatureIsOn("learning_pathways");
  const hasJobProfileCTAToggle = useFeature("job_profile_cta").value;
  const hasCompletedJobProfile = hasJobProfile(currentUser);
  const showUpdateProfileCTA =
    hasJobProfileCTAToggle && !hasCompletedJobProfile;

  if (showNavigation !== "profile" || !currentUser) {
    return null;
  }

  const { activeIndividualBudget } = currentUser;

  const close = () => setShowNavigation("default");
  const budget = activeIndividualBudget && activeIndividualBudget.remaining;
  const formattedBudget =
    budget > 0 &&
    formatCurrencyValue(budget, currentUser.currency, currentUser.locale);

  const lastDayOfBudget =
    activeIndividualBudget &&
    moment(activeIndividualBudget.endDate).format("D MMMM YYYY");

  return (
    <div className={styles.container}>
      {currentUser.activeIndividualBudget && (
        <div className={styles.budget}>
          {formattedBudget
            ? `You have ${formattedBudget} left to spend until ${lastDayOfBudget} 😄`
            : `You have no budget left to spend 😥`}
        </div>
      )}
      <div className={styles.navigation}>
        <div className={styles.userInfo}>
          <div className={styles.imageContainer}>
            <UserImage image={currentUser.image} />
          </div>
          {showUpdateProfileCTA && <div className={styles.notificationDot} />}
          <div className={styles.username}>
            {`${currentUser.firstName} ${currentUser.lastName}`}
          </div>
          <div className={styles.organisation}>
            {currentUser.organisation.name}
          </div>
        </div>
        <PageLink to="/me/" onClick={close}>
          Profile
        </PageLink>

        {showUpdateProfileCTA && (
          <Link
            to={"/me/edit"}
            className={styles.updateProfile}
            onClick={() => track("Update Profile Button Clicked")}
          >
            <Text textStyle="small" textVariant="bold">
              Update profile
            </Text>
          </Link>
        )}
        <PageLink to="/requests/" onClick={close}>
          Requests
        </PageLink>
        {hasLearningPathways && (
          <PageLink to="/me/pathways" onClick={close}>
            Pathways
          </PageLink>
        )}
        {hasSelfServePlaylist(currentUser) && (
          <PageLink to="/me/playlists" onClick={close}>
            Playlists
          </PageLink>
        )}
        <PageLink to="/bookmarks/" onClick={close}>
          Bookmarks
        </PageLink>
        <PageLink to="/goals/" onClick={close}>
          Learning goals
        </PageLink>
        <Link to="/logout" className={styles.signout} onClick={close}>
          Sign out
        </Link>
      </div>
    </div>
  );
};

export default ProfileNavigation;
