import { FunctionComponent } from "react";
import { Text, TextButton } from "@learnerbly/web-components";
import styles from "./styles.scss";
import UserImage from "../user-image/user-image";
import classNames from "classnames";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getJobFamilyEditorialContent } from "src/shared/util/job-profile";
import { track } from "src/shared/util/segment";

const jobFamilyStyles: Record<string, string> = {
  Data: styles.data,
  "People/HR": styles.people,
  Product: styles.product,
  Sales: styles.sales,
  "Software engineering": styles.engineering,
};

export enum EntityType {
  Resource = "resource",
  Playlist = "playlist",
}

const entityPaths: Record<EntityType, string> = {
  [EntityType.Resource]: "resources",
  [EntityType.Playlist]: "playlists",
};

export interface EditoralContent {
  title: string;
  description?: string;
  images: Array<string>;
  creator?: {
    name: string;
    image?: string | null;
  };
  type: EntityType;
  entityId: string;
  linkText: string;
}

interface EditorialBannerProps {
  jobFamily: string;
}

const EditorialBanner: FunctionComponent<EditorialBannerProps> = ({
  jobFamily,
}) => {
  const content = getJobFamilyEditorialContent(jobFamily);

  if (!content) {
    return null;
  }

  const familyStyle = jobFamilyStyles[jobFamily];

  const trackClick = () => {
    if (content.type === "resource") {
      trackResource();
    } else if (content.type === "playlist") {
      trackPlaylist();
    }
  };

  const trackResource = () => {
    track("Product Clicked", {
      jobFamily,
      context: "Job Family Editorial",
      product_id: content.entityId,
      sku: content.entityId,
      name: content.title,
      index: `${process.env.STAGE}_resources`,
      objectID: content.entityId,
      eventType: "click",
    });
  };

  const trackPlaylist = () => {
    track("PlaylistCard clicked", {
      jobFamily,
      trackContext: "Job Family Editorial",
      playlist: content.title,
      playlistCreator: content?.creator?.name,
    });
  };

  return (
    <div className={classNames(styles.container, familyStyle)}>
      <div
        className={classNames(styles.images, {
          [styles.singleImage]: content.images.length === 1,
        })}
      >
        {content.images.map((image, index) => (
          <img key={index} src={image} role="presentation" />
        ))}
      </div>
      <div className={styles.info}>
        <Text textStyle="subheading" textVariant="bold">
          {content.title}
        </Text>
        {content.creator && (
          <div className={styles.creator}>
            <div className={styles.creatorAvatar}>
              <UserImage
                firstName={content.creator.name}
                image={content.creator.image && content.creator.image}
              />
            </div>
            <div>{content.creator.name}</div>
          </div>
        )}
        {content.description && <Text>{content.description}</Text>}
        <Link
          to={`/${entityPaths[content.type]}/${content.entityId}`}
          className={styles.link}
          onClick={trackClick}
        >
          <TextButton icon={faArrowRight} iconRight>
            {content.linkText}
          </TextButton>
        </Link>
      </div>
    </div>
  );
};

export default EditorialBanner;
