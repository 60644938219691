import { useGrowthBook, useFeatureIsOn } from "@growthbook/growthbook-react";
import { FunctionComponent, PropsWithChildren } from "react";
import { useAppContext } from "../../../app-context";
import { User } from "../../util/user-constraints";
import NotFoundPage from "../not-found-page";

export const IsAuthorised: FunctionComponent<
  PropsWithChildren<{ anyOf?: Array<(user: User) => boolean> }>
> = ({ anyOf = [], children }) => {
  const { currentUser } = useAppContext();

  if (!currentUser) {
    return <NotFoundPage />;
  }

  if (anyOf.every((check) => !check(currentUser))) {
    return <NotFoundPage />;
  }

  return <>{children}</>;
};

export const IsAuthorisedFeature: FunctionComponent<
  PropsWithChildren<{ feature: string; anyOf?: Array<(user: User) => boolean> }>
> = ({ feature, anyOf = [], children }) => {
  const { currentUser } = useAppContext();
  const featureEnabled = useFeatureIsOn(feature);

  if (featureEnabled) {
    if (
      currentUser &&
      anyOf.length > 0 &&
      anyOf.every((check) => !check(currentUser))
    ) {
      return <NotFoundPage />;
    }
  } else {
    return <NotFoundPage />;
  }
  return <>{children}</>;
};

/** A auth route that supports a mix of both growthbook and inhouse feature toggles */
export const IsAuthorisedFeatureMix: FunctionComponent<
  PropsWithChildren<{
    anyOf?: Array<(user: User) => boolean>;
    anyOfFeature: Array<string>;
  }>
> = ({ anyOf = [], anyOfFeature = [], children }) => {
  const { currentUser } = useAppContext();
  const gb = useGrowthBook();

  const enabled = anyOf.some((check) => check(currentUser));
  if (enabled) {
    return <>{children}</>;
  }

  const growthBookEnabled = anyOfFeature.some((feature) => gb?.isOn(feature));
  if (growthBookEnabled) {
    return <>{children}</>;
  }

  return <NotFoundPage />;
};
