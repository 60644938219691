import * as React from "react";
import * as Sentry from "@sentry/browser";

import PropTypes from "prop-types";

import { logError } from "../../util/error-logging";
import ErrorMessage from "../error";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    Sentry.withScope((scope) => {
      Object.keys(info).forEach((key) => scope.setExtra(key, info[key]));
      logError(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

// eslint-disable-next-line react/display-name
const withErrorBoundary = (Component) => (props) =>
  (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );

export default withErrorBoundary;
