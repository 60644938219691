import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.scss";
import { FunctionComponent, useContext } from "react";
import { AppContext } from "src/app-context";

const ImpersonateBanner: FunctionComponent = () => {
  const { currentUser } = useContext(AppContext);

  if (!currentUser) {
    return null;
  }

  const { firstName, lastName, organisation } = currentUser;

  return (
    <div className={styles.container}>
      <FontAwesomeIcon icon={faExclamationTriangle} /> Achtung! You’re currently
      browsing as {`${firstName} ${lastName} from ${organisation.name}`}
    </div>
  );
};

export default ImpersonateBanner;
