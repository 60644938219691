import * as Sentry from "@sentry/browser";

export const logError = (error: unknown): string =>
  Sentry.captureException(error);

interface UserForContext {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  organisation: {
    id: string;
  };
}

export const setupUserContext = (user?: UserForContext): void => {
  if (user) {
    Sentry.configureScope((scope) =>
      scope.setUser({
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        organisation: user.organisation.id,
      }),
    );
  }
};
