import { FunctionComponent, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import decode from "jwt-decode";
import { validate as validateEmail } from "email-validator";
import { useAppContext } from "src/app-context";
import Loading from "src/shared/components/loading";
import { Auth } from "@aws-amplify/auth";

const getEmailFromToken = (token: string): string | null => {
  try {
    // jwt-decode doesn't check the signature, but that's fine since we
    // don't need to trust the token, we're just extracting the email
    // and passing it to Cognito where it will be verified
    const jwt = decode<{ email?: string }>(token);

    if (!jwt || !jwt.email || !validateEmail(jwt.email)) {
      return null;
    }

    return jwt.email;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const MagicLink: FunctionComponent = () => {
  const { setAlert } = useAppContext();
  const history = useHistory();
  const { hash } = useLocation();

  const redirectToLogin = (message?: string) => {
    if (message) {
      setAlert({
        message,
        type: "error",
      });
    }

    history.push("/login");
  };

  const signInWithToken = async (email: string, token: string) => {
    try {
      const res = await Auth.signIn(email);
      await Auth.sendCustomChallengeAnswer(res, token);
      window.location.reload();
    } catch (error: any) {
      console.error(error);
      redirectToLogin(error.message || "Magic link is not valid");
    }
  };

  useEffect(() => {
    if (!hash) {
      redirectToLogin();
      return;
    }

    const token = hash.replace(/^#/, "");

    const email = getEmailFromToken(token);
    if (!email) {
      redirectToLogin("Magic link is not valid");
      return;
    }

    signInWithToken(email, token);
  }, [location]);

  return <Loading />;
};

export default MagicLink;
