import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { default as ExperimentSearchForm } from "./experiment-search-form";
import { default as DefaultSearchForm } from "./search-form";
import SearchBarWithSuggestions from "./search-bar-with-suggestions";

type SearchFormProps = {
  onSearch?: () => void;
  autoFocus?: boolean;
} & Record<string, unknown>;

const SearchForm = (props: SearchFormProps): JSX.Element => {
  const hasIntelligentSearchEnabled = useFeatureIsOn("intelligent_search_mvp");
  const hasQuerySuggestionEnabled = useFeatureIsOn(
    "algolia_search_query_suggestions",
  );

  if (hasIntelligentSearchEnabled) {
    return <ExperimentSearchForm />;
  }
  if (hasQuerySuggestionEnabled) {
    return <SearchBarWithSuggestions onSearch={props.onSearch} />;
  }
  return <DefaultSearchForm {...props} />;
};

export default SearchForm;
