import { useFeature } from "@growthbook/growthbook-react";
import moment from "moment";

import { FunctionComponent, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "src/app-context";
import useMediaQuery from "src/shared/hooks/use-media-query";
import { track } from "src/shared/util/segment";
import { formatCurrencyValue } from "src/shared/util/text-display";
import {
  hasAdHocRequests,
  hasStartedPDP,
  isPotsApprover,
  isOrgAdmin,
} from "src/shared/util/user-constraints";
import { getNavLinks } from "./get-nav-links";

import Header from "./Header";

const trackClick = (title: string) => {
  track("Clicked Nav", {
    title,
  });
};

export const HeaderWrapper: FunctionComponent = () => {
  const isDesktop = useMediaQuery("(min-width: 1184px)");

  const { currentUser, orgPageUrl, setShowNavigation, showNavigation } =
    useContext(AppContext);

  const { pathname } = useLocation();
  const hasPotsEnabled = useFeature("pots").value;

  if (!currentUser) {
    return null;
  }

  const {
    activeIndividualBudget,
    organisation: { logo },
    tasks: { approvals },
  } = currentUser;
  const remainingBudget =
    activeIndividualBudget && activeIndividualBudget.remaining
      ? activeIndividualBudget.remaining
      : 0;

  const formattedBudgetWithDecimals =
    activeIndividualBudget &&
    formatCurrencyValue(
      Math.max(0, remainingBudget),
      activeIndividualBudget.currency,
      currentUser.locale,
    );

  const lastDayOfBudget =
    activeIndividualBudget &&
    moment(activeIndividualBudget.endDate).format("D MMMM YYYY");

  const isBudgetEmpty = remainingBudget <= 0;

  const openGoals = currentUser.goals
    ? currentUser.goals.filter(
        (goal: { status: string }) => goal.status === "OPEN",
      )
    : [];

  const hasPendingApprovals = approvals.length > 0;

  const userIsApprover = isPotsApprover(currentUser);
  const userIsOrgAdmin = isOrgAdmin(currentUser);
  const collapseLinksEarly = userIsApprover || userIsOrgAdmin;

  const navLinks = getNavLinks({
    orgPageUrl,
    pathname,
    userIsApprover,
    userIsOrgAdmin,
    hasPendingApprovals,
    hasPotsEnabled,
    trackClick,
    isForMobile: false,
  });

  const headerProps = {
    collapseLinksEarly,
    formattedBudgetWithDecimals,
    hasAdHocRequests: hasAdHocRequests(currentUser),
    hasPotsEnabled,
    hasStartedPDP: hasStartedPDP(currentUser),
    isBudgetEmpty,
    isDesktop,
    lastDayOfBudget,
    logo,
    openGoals,
    orgPageUrl,
    showNavigation,
    setShowNavigation,
    trackClick,
    navLinks,
  };

  return <Header {...headerProps} />;
};

export default HeaderWrapper;
