import PropTypes from "prop-types";

import { Provider as AdminStateProvider } from "./admin";

const State = ({ children }) => (
  <AdminStateProvider>{children}</AdminStateProvider>
);

State.propTypes = {
  children: PropTypes.node.isRequired,
};

export default State;
