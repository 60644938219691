import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";

import OffPlatformResourceButton from "src/shared/components/off-platform-resource-button";
import RequestAnythingButton from "src/shared/components/request-anything-button";
import SearchForm from "../search-form";

import { NavLinkObject } from "../NewHeader/get-nav-links";

import styles from "./styles.scss";

interface Props {
  hasAdHocRequests: boolean;
  links: Array<NavLinkObject>;
  trackClick: (title: string) => void;
  setShowNavigation: (type: string) => void;
}
const HamburgerNavigation: FunctionComponent<Props> = ({
  hasAdHocRequests,
  links,
  trackClick,
  setShowNavigation,
}) => {
  return (
    <div className={styles.container} data-cy="hamburger-navigation">
      <div className={styles.navigation}>
        <div className={styles.searchBox}>
          <SearchForm onSearch={() => setShowNavigation("default")} />
        </div>

        {links.map(({ onClick, label, to }, index) => (
          <NavLink
            key={index}
            activeClassName={styles.active}
            className={styles.link}
            exact
            onClick={onClick}
            to={to}
          >
            {label}
          </NavLink>
        ))}

        <div className={styles.adhocRequests}>
          {hasAdHocRequests ? (
            <div className={styles.requestButton}>
              <RequestAnythingButton />
            </div>
          ) : (
            <div className={styles.requestButton}>
              <OffPlatformResourceButton trackClick={trackClick} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HamburgerNavigation;
