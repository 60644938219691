import type { ReactElement } from "react";
import { Helmet } from "react-helmet";

const PageMeta = ({
  title,
  description,
}: {
  title?: string;
  description?: string;
}): ReactElement => (
  <Helmet titleTemplate="%s | Learnerbly">
    <title>{title}</title>
    {description && <meta name="description" content={description} />}
  </Helmet>
);

export default PageMeta;
