import { FunctionComponent, useContext } from "react";

import { AppContext } from "../../../app-context";

import styles from "./styles.scss";

export const Navigation: FunctionComponent = () => {
  const { currentUser, showNavigation } = useContext(AppContext);
  if (!currentUser || !showNavigation) {
    return null;
  }

  return <div className={styles.spacer} />;
};

export default Navigation;
