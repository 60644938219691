import { FunctionComponent } from "react";
import classnames from "classnames";

import styles from "./styles.scss";

interface Props {
  darkBg?: boolean;
  fullScreen?: boolean;
  white?: boolean;
  wideLegacy?: boolean;
  block?: boolean;
  yellow?: boolean;
  wide?: boolean;
  condensed?: boolean;
}

const MainContainer: FunctionComponent<Props> = ({
  children,
  darkBg,
  white,
  yellow,
  wideLegacy,
  fullScreen,
  block,
  wide,
  condensed,
}) => {
  const classes = classnames(styles.container, {
    [styles.block]: block,
    [styles.darkBg]: darkBg,
    [styles.fullScreen]: fullScreen,
    [styles.white]: white,
    [styles.yellow]: yellow,
  });

  const contentClasses = classnames({
    [styles.block]: block,
    [styles.content]: !fullScreen,
    [styles.fullScreenContent]: fullScreen,
    [styles.wideLegacy]: wideLegacy,
    [styles.wide]: wide,
    [styles.condensed]: condensed,
  });

  return (
    <main className={classes} id="mainContent">
      <div className={contentClasses}>{children}</div>
    </main>
  );
};

export default MainContainer;
