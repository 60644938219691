import { debounce } from "lodash";
import { useCallback, useEffect } from "react";
import { track } from "src/shared/util/segment";

type SearchType = "lexical" | "semantic";

export const useTrackSearchCommand = ({
  search,
  ai,
}: {
  search: string;
  ai: boolean;
}): void => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const trackEvent = useCallback(
    debounce<(search: string, searchType: SearchType) => void>(
      (search, searchType) => {
        track("Products Searched", {
          query: search,
          context: "Intelligent Search",
          searchType,
        });
      },
      1000,
    ),
    [],
  );

  useEffect(() => {
    if (!search) {
      return;
    }

    const searchType: SearchType = ai ? "semantic" : "lexical";

    trackEvent(search, searchType);
  }, [search, ai, trackEvent]);
};
