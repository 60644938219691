import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compose } from "redux";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  reduxForm,
  Field,
  InjectedFormProps,
  WrappedFieldProps,
} from "redux-form";
import { Redirect, useHistory } from "react-router-dom";
import { FunctionComponent, useEffect, useState } from "react";
import classnames from "classnames";

import PropTypes from "prop-types";
import qs from "qs";

import { track } from "../../util/segment";
import Form from "../form";
import styles from "./styles.scss";

interface FormData {
  query: string;
}

interface SearchFieldProps {
  autoFocus: boolean;
}

interface SearchFormProps {
  onSearch?(): void;
  autoFocus?: boolean;
}

const SearchField = ({
  input,
  meta: { active },
  autoFocus,
}: SearchFieldProps & WrappedFieldProps) => (
  <div
    className={classnames(styles.container, { [styles.active]: active })}
    role="search"
  >
    <input
      aria-label="Search input"
      className={styles.input}
      data-cy="search-field"
      data-testid="search-field"
      placeholder="Search..."
      autoComplete="off"
      autoFocus={autoFocus}
      title="Search"
      type="search"
      {...input}
    />
    <div className={styles.icon} aria-hidden="true">
      <button type="submit" tabIndex={-1} aria-label="Search">
        <FontAwesomeIcon icon={faSearch} />
      </button>
    </div>
  </div>
);

SearchField.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  autoFocus: PropTypes.bool,
};

// Redirects user to the search page
const SearchForm: FunctionComponent<
  SearchFormProps & InjectedFormProps<FormData, SearchFormProps>
> = ({ change, handleSubmit, onSearch, autoFocus = false }) => {
  const [redirectDestination, setRedirectDestination] = useState<string>();

  const history = useHistory();

  useEffect(() => {
    const { query } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    if (query) {
      change("query", query);
    }

    // when the change function from redux-form is included in the useEffect dependencies array we encounter
    // a bug where we are unable to fully delete the query from the component. other solutions welcomed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  useEffect(() => {
    if (!redirectDestination || !onSearch) {
      return;
    }

    onSearch();
  }, [redirectDestination, onSearch]);

  const handleSearch = ({ query }: FormData) => {
    track("Products Searched", { query, context: "Header" });

    setRedirectDestination(
      `/search${qs.stringify({ query }, { addQueryPrefix: true })}`,
    );
  };

  useEffect(() => {
    if (!redirectDestination) {
      return;
    }

    setRedirectDestination(undefined);
  }, [redirectDestination]);

  return (
    <>
      {redirectDestination && <Redirect to={redirectDestination} />}

      <Form name="resource-search" onSubmit={handleSubmit(handleSearch)}>
        <Field name="query" component={SearchField} autoFocus={autoFocus} />
      </Form>
    </>
  );
};

export default compose(
  reduxForm<FormData, SearchFormProps>({
    form: "resource-search",
    enableReinitialize: true,
  }),
)(SearchForm);
