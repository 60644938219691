import { useContext } from "react";

import createState from "../util/create-state";

const initialState = {
  selectedUsers: [],
  areAllUsersSelected: false,
};

const actions = {
  selectUsers: (state, { selectedUsers }) => ({
    ...state,
    selectedUsers,
  }),
  clearAllSelectedUsers: () => ({
    ...initialState,
  }),
  setAreAllUsersSelected: (state, areAllUsersSelected) => ({
    ...state,
    areAllUsersSelected,
  }),
};

const { context, Provider } = createState(initialState, actions);

export { Provider };

export default () => useContext(context);
