import { useQuery } from "@apollo/client";
import { FunctionComponent, useContext } from "react";

import { AppContext } from "src/app-context";

import Error from "src/shared/components/error";
import InfiniteResources from "src/shared/components/resources";
import Loading from "src/shared/components/loading";

import getResourcesByTypeQuery from "./get-resources-by-type.ts.graphql";

import {
  getResourcesByType as TGetResourcesByType,
  getResourcesByTypeVariables as TGetResourcesByTypeVariables,
} from "./__generated__/getResourcesByType";

import { getResourceData } from "./helpers";
import { Page } from "src/shared/components/Page/Page";

const ResourceTypePage: FunctionComponent<{ resourceType: string }> = ({
  resourceType,
}) => {
  const { id: resourceTypeId, pageTitle } = getResourceData(resourceType);

  const {
    currentUser: { country, currency },
  } = useContext(AppContext);

  const { data, error, fetchMore, loading } = useQuery<
    TGetResourcesByType,
    TGetResourcesByTypeVariables
  >(getResourcesByTypeQuery, {
    variables: {
      contentRegion: country,
      currency: currency,
      filter: {
        types: [resourceTypeId],
      },
    },
  });

  const loadMoreEntries = () =>
    fetchMore({
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const nextResources = fetchMoreResult.resources;
        const nextResults = fetchMoreResult.resources.results;
        const previousResults = previousResult.resources.results;

        return {
          ...previousResult,
          resources: {
            ...nextResources,
            results: [...previousResults, ...nextResults],
          },
        };
      },
      variables: {
        searchAfter,
      },
    });

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <Error />;
  }

  const {
    resources: { results, searchAfter },
  } = data;

  return (
    <Page title={pageTitle}>
      <InfiniteResources
        hasMore={!!searchAfter}
        loadMoreEntries={loadMoreEntries}
        resources={results}
        trackContext={pageTitle}
      />
    </Page>
  );
};

export default ResourceTypePage;
