// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".l6yyWDHs_k6IXPvzdJYh{display:flex;flex-direction:column;min-height:calc(100vh - 3.375rem)}.M0UOk_Y4RxnsWxAnt9Xv{max-height:100vh;height:100%;overflow:hidden}@media screen and (min-width: 65em){.M0UOk_Y4RxnsWxAnt9Xv{max-height:100%;overflow:auto}}\n", "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AAIA,sBACE,YAAa,CACb,qBAAsB,CACtB,iCAA2C,CAC5C,sBAGC,gBAAiB,CACjB,WAAY,CACZ,eAAgB,CAEhB,oCALF,sBAMI,eAAgB,CAChB,aAAc,CAEjB","sourcesContent":["@import \"./shared/styles/variables\";\n\n$footer-height: 3.375rem;\n\n.appContainer {\n  display: flex;\n  flex-direction: column;\n  min-height: calc(100vh - #{$footer-height});\n}\n\n.fixed {\n  max-height: 100vh;\n  height: 100%;\n  overflow: hidden;\n\n  @media screen and (min-width: $container-max-width) {\n    max-height: 100%;\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appContainer": "l6yyWDHs_k6IXPvzdJYh",
	"fixed": "M0UOk_Y4RxnsWxAnt9Xv"
};
export default ___CSS_LOADER_EXPORT___;
