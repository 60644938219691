// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A7SorAhiPfnDIwXf5xoU{margin:2.5rem auto;padding:0px 1.5rem;width:100%}@media screen and (min-width: 48em){.A7SorAhiPfnDIwXf5xoU{display:flex;flex-direction:column;padding:0px 2.5rem}}@media screen and (min-width: 1096px){.A7SorAhiPfnDIwXf5xoU{max-width:62.5rem;padding:0}}@media screen and (min-width: 1096px){.A7SorAhiPfnDIwXf5xoU.wmeOB8PgGa9SjEse1FHq{max-width:75rem;padding:0 2.5rem}}.uzpIAwpc3O2gj8sRIpPN{margin-bottom:1.5rem;display:flex;justify-content:space-between}@media (max-width: 48em){.uzpIAwpc3O2gj8sRIpPN{flex-direction:column}}@media (max-width: 48em){.uzpIAwpc3O2gj8sRIpPN h2{margin-bottom:1rem}}\n", "",{"version":3,"sources":["webpack://./src/shared/components/Page/styles.scss"],"names":[],"mappings":"AAEA,sBACE,kBAAmB,CACnB,kBAAmB,CACnB,UAAW,CAEX,oCALF,sBAMI,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CActB,CAXC,sCAXF,sBAYI,iBAAkB,CAClB,SAAU,CASb,CALG,sCAjBJ,2CAkBM,eAAgB,CAChB,gBAAiB,CAEpB,CAGH,sBACE,oBAAqB,CACrB,YAAa,CACb,6BAA8B,CAE9B,yBALF,sBAMI,qBAAsB,CAEzB,CAGC,yBADF,yBAEI,kBAAmB,CAEtB","sourcesContent":["@import \"src/shared/styles/variables\";\n\n.container {\n  margin: 2.5rem auto;\n  padding: 0px 1.5rem;\n  width: 100%;\n\n  @media screen and (min-width: $sm-screen) {\n    display: flex;\n    flex-direction: column;\n    padding: 0px 2.5rem;\n  }\n\n  @media screen and (min-width: 1096px) {\n    max-width: 62.5rem;\n    padding: 0;\n  }\n\n  &.wide {\n    @media screen and (min-width: 1096px) {\n      max-width: 75rem;\n      padding: 0 2.5rem;\n    }\n  }\n}\n\n.heading {\n  margin-bottom: 1.5rem;\n  display: flex;\n  justify-content: space-between;\n\n  @media (max-width: $sm-screen) {\n    flex-direction: column;\n  }\n}\n\n.heading h2 {\n  @media (max-width: $sm-screen) {\n    margin-bottom: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "A7SorAhiPfnDIwXf5xoU",
	"wide": "wmeOB8PgGa9SjEse1FHq",
	"heading": "uzpIAwpc3O2gj8sRIpPN"
};
export default ___CSS_LOADER_EXPORT___;
