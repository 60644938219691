import { FunctionComponent } from "react";

const Logo: FunctionComponent<{ height?: string; width?: string }> = ({
  height,
  width,
}) => (
  <svg fill="none" height={height} viewBox="0 0 96 135" width={width}>
    <g clipPath="url(#clip0)">
      <g transform="matrix(0.39834028 0 0 0.396875 0 0)">
        <path
          d="M241 215L241 320L52.5 320C 23.5051 320 -1.26741E-06 296.495 0 267.5C 1.26741E-06 238.505 23.5051 215 52.5 215L52.5 215L241 215z"
          stroke="none"
          fill="#FF8A00"
          fillRule="nonzero"
        />
        <path
          d="M6.79571E-07 267.484C -8.49465E-07 267.49 6.79571E-07 267.495 6.79571E-07 267.5C 6.79571E-07 296.495 23.5051 320 52.5 320C 52.5095 320 52.5191 320 52.5286 320C 81.5104 319.985 105 296.485 105 267.5L105 267.5L105 215L52.5 215C 23.5103 215 0.00847707 238.497 6.79571E-07 267.484z"
          stroke="none"
          fill="#3400C7"
          fillRule="nonzero"
        />
        <path
          d="M105 0L0 0L0 267.484C 0.00847707 238.497 23.5103 215 52.5 215L52.5 215L105 215L105 0z"
          stroke="none"
          fill="#D2004C"
          fillRule="nonzero"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="95.2" height="134.4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Logo.defaultProps = {
  width: "16",
  height: "24",
};

export default Logo;
