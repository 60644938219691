// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OMB6FWHeaNzm2OsDCBYE{display:flex;justify-content:flex-end;align-items:center;gap:0.8rem}.OMB6FWHeaNzm2OsDCBYE button{color:#69738a;font-weight:normal}.OMB6FWHeaNzm2OsDCBYE button:hover{color:#00856c;text-decoration:none;cursor:pointer}.OMB6FWHeaNzm2OsDCBYE .ns6PrFJyeXsAVAvZ5Out{display:flex;align-items:center;gap:0.3rem}.OMB6FWHeaNzm2OsDCBYE .ns6PrFJyeXsAVAvZ5Out kbd{display:flex;align-items:center;justify-content:center;background:#dad8d8;color:#6f6f6f;height:20px;width:20px;border-radius:4px;padding:0 0.3rem;font-size:0.8rem}\n", "",{"version":3,"sources":["webpack://./src/shared/components/search-command/components/CommandDialogFooter/AdvancedSearchButton/styles.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACb,wBAAyB,CACzB,kBAAmB,CACnB,UAAW,CAJb,6BAOI,aAAc,CACd,kBAAmB,CARvB,mCAUM,aAAc,CACd,oBAAqB,CACrB,cAAe,CAZrB,4CAiBI,YAAa,CACb,kBAAmB,CACnB,UAAW,CAnBf,gDAsBM,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,kBAAmB,CACnB,aAAc,CACd,WAAY,CACZ,UAAW,CACX,iBAAkB,CAClB,gBAAiB,CACjB,gBAAiB","sourcesContent":[".advancedSearchButtonContainer {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 0.8rem;\n\n  button {\n    color: #69738a;\n    font-weight: normal;\n    &:hover {\n      color: #00856c;\n      text-decoration: none;\n      cursor: pointer;\n    }\n  }\n\n  .commandFooterKeys {\n    display: flex;\n    align-items: center;\n    gap: 0.3rem;\n\n    kbd {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      background: #dad8d8;\n      color: #6f6f6f;\n      height: 20px;\n      width: 20px;\n      border-radius: 4px;\n      padding: 0 0.3rem;\n      font-size: 0.8rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advancedSearchButtonContainer": "OMB6FWHeaNzm2OsDCBYE",
	"commandFooterKeys": "ns6PrFJyeXsAVAvZ5Out"
};
export default ___CSS_LOADER_EXPORT___;
