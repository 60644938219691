export interface AdditionalTrackingContext {
  index: string;
  position: number;
  queryID: string | undefined;
}

const santitiseString = (str?: string): string => {
  return (str || "").replace(/[^a-zA-Z0-9_\- ]/g, "");
};

export const sanitiseTrackingContext = (
  context: unknown,
): AdditionalTrackingContext | null => {
  if (!context || typeof context !== "object") {
    return null;
  }

  const trackingContext = context as AdditionalTrackingContext;
  const { queryID, index } = trackingContext;

  if (typeof queryID !== "string" || typeof index !== "string") {
    return null;
  }

  return {
    index: santitiseString(trackingContext.index),
    position: trackingContext.position,
    queryID: santitiseString(trackingContext.queryID),
  };
};

export const encodeTrackingContext = (
  context: AdditionalTrackingContext | null,
): string => {
  const contextParams = new URLSearchParams();

  if (context && context.queryID && context.index) {
    contextParams.set("queryID", context.queryID);
    contextParams.set("index", context.index);
  }

  const contextQueryString = contextParams.toString();
  return contextQueryString ? `?${contextQueryString}/` : "";
};
