import classnames from "classnames";
import PropTypes from "prop-types";
import { FunctionComponent } from "react";

import placeholder from "./placeholder.svg";

import styles from "./styles.scss";

const UserImage: FunctionComponent<{
  firstName?: string;
  image?: string | null;
  lastName?: string;
}> = ({ firstName, image, lastName }) => (
  <div className={classnames(styles.container)}>
    <img
      src={image ? image : placeholder}
      alt={
        firstName && lastName
          ? `${firstName} ${lastName} avatar`
          : "user avatar"
      }
      className={styles.image}
    />
  </div>
);

UserImage.propTypes = {
  image: PropTypes.string,
};

export default UserImage;
