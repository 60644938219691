import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { SearchCommandContext } from "../../contexts/SearchCommandContext";
import { AdvancedSearchButton } from "./AdvancedSearchButton";
import { AICheckboxButton } from "./AICheckboxButton";

import styles from "./styles.scss";

export const CommanddialogFooter = (): JSX.Element => {
  const searchCommandContext = useContext(SearchCommandContext);
  const history = useHistory();

  const onAdvancedSearchClick = () => {
    searchCommandContext.setOpen(false);
    history.push(`/search/?query=${searchCommandContext.search}`);
  };

  return (
    <div className={styles.commandFooter}>
      <AICheckboxButton
        value={searchCommandContext.ai}
        onChange={searchCommandContext.setAi}
      />

      <AdvancedSearchButton onClick={onAdvancedSearchClick} />
    </div>
  );
};
