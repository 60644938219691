import { ReactNode } from "react";

import styles from "./styles.scss";

interface GetNavLinksArgs {
  orgPageUrl: string;
  pathname: string;
  userIsApprover: boolean;
  userIsOrgAdmin: boolean;
  hasPendingApprovals?: boolean;
  hasPotsEnabled: boolean;
  trackClick: (page: string) => void;
  isForMobile?: boolean;
}

export type NavLinkObject = {
  to: string;
  label: string | ReactNode;
  exact?: boolean;
  onClick: () => void;
  isActive?: () => boolean;
};

export const getNavLinks = ({
  orgPageUrl,
  pathname,
  userIsApprover,
  userIsOrgAdmin,
  hasPendingApprovals = false,
  hasPotsEnabled,
  trackClick,
  isForMobile = false,
}: GetNavLinksArgs): Array<NavLinkObject> => {
  const navLinks = [
    {
      to: "/",
      label: "Home",
      exact: true,
      onClick: () => trackClick("Home"),
    },
    {
      to: "/explore/",
      label: "Explore",
      onClick: () => trackClick("Explore"),
    },
  ] as Array<NavLinkObject>;

  const showCreateLink = hasPotsEnabled && (userIsOrgAdmin || userIsApprover);
  if (showCreateLink) {
    navLinks.push({
      to: `/create/`,
      ...(isForMobile && {
        isActive: () => pathname === "/create",
      }),
      onClick: () => trackClick("Create"),
      label: "Create",
    });
  }

  if (userIsApprover) {
    if (!hasPotsEnabled) {
      navLinks.push({
        to: "/approvals/",
        ...(isForMobile && {
          isActive: () =>
            pathname === "/approvals/" || pathname === "/approvals/history/",
        }),
        onClick: () => trackClick("Team"),
        label: (
          <div className={styles.notificationWrapper}>
            Team
            {hasPendingApprovals && <div className={styles.pendingBadge} />}
          </div>
        ),
      });
    }

    if (hasPotsEnabled) {
      navLinks.push({
        to: "/approve/requests/",
        ...(isForMobile && {
          isActive: () =>
            pathname === "/approve/requests/" ||
            pathname === "/approve/history/",
        }),
        onClick: () => trackClick("Approve"),
        label: (
          <div className={styles.notificationWrapper}>
            Approve
            {hasPendingApprovals && <div className={styles.pendingBadge} />}
          </div>
        ),
      });
    }
  }

  if (userIsOrgAdmin) {
    navLinks.push({
      to: `/${orgPageUrl}/`,
      exact: true,
      label: "Admin",
      ...(isForMobile && {
        isActive: () =>
          pathname === `/${orgPageUrl}/` ||
          pathname === `/${orgPageUrl}/account/` ||
          pathname === `/${orgPageUrl}/content/` ||
          pathname === `/${orgPageUrl}/events/` ||
          pathname === `/${orgPageUrl}/people/` ||
          pathname === `/${orgPageUrl}/groups/` ||
          pathname === `/${orgPageUrl}/requests/` ||
          pathname === `/${orgPageUrl}/settings/`,
      }),
      onClick: () => trackClick("Admin"),
    });
  }

  return navLinks;
};
