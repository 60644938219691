import { ButtonHTMLAttributes, FunctionComponent, ReactNode } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./styles.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const ButtonLegacy: FunctionComponent<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    children: ReactNode;
    fullWidth?: boolean;
    loading?: boolean;
    secondary?: boolean;
    blue?: boolean;
    green?: boolean;
    link?: boolean;
    smallBlue?: boolean;
    smallGreen?: boolean;
    icon?: IconProp;
    rounded?: boolean;
    roundedGreen?: boolean;
    roundedTransparent?: boolean;
    testId?: string;
  }
> = ({
  children,
  smallBlue,
  smallGreen,
  fullWidth,
  secondary,
  blue,
  green,
  link,
  loading,
  icon,
  rounded,
  roundedGreen,
  disabled,
  roundedTransparent,
  className,
  testId,
  ...props
}) => {
  const classes = classnames(styles.container, className, {
    [styles.fullWidth]: fullWidth,
    [styles.loading]: loading,
    [styles.secondary]: secondary,
    [styles.blue]: blue,
    [styles.green]: green,
    [styles.link]: link,
    [styles.smallBlue]: smallBlue,
    [styles.smallGreen]: smallGreen,
    [styles.rounded]: rounded,
    [styles.roundedGreen]: roundedGreen,
    [styles.roundedTransparent]: roundedTransparent,
    [styles.roundedIcon]: Boolean(icon),
  });

  return (
    <button
      type="button"
      className={classes}
      data-cy={testId}
      {...props}
      disabled={!!disabled}
    >
      {loading ? (
        <span className={styles.loadingIcon}>
          <div className={styles.bounce1} />
          <div className={styles.bounce2} />
          <div className={styles.bounce3} />
        </span>
      ) : (
        <span>
          {icon ? (
            <FontAwesomeIcon icon={icon} className={styles.buttonIcon} />
          ) : null}
          {children}
        </span>
      )}
    </button>
  );
};

export default ButtonLegacy;
