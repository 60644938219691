import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  NormalizedCacheObject,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import noop from "lodash/noop";

import { getToken } from "./auth";
import { relayStylePagination } from "@apollo/client/utilities";

declare global {
  interface Window {
    __APOLLO_STATE__: NormalizedCacheObject;
  }
}

const clients: Record<string, ApolloClient<NormalizedCacheObject>> = {};

export function createApolloClient(
  uri: string,
): ApolloClient<NormalizedCacheObject> {
  if (clients[uri]) {
    return clients[uri];
  }

  const httpLink = new HttpLink({ uri });

  const errorLink = onError(noop);

  const authMiddleware = setContext(async (_, { headers }) => {
    const token = await getToken();
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });

  const errorHttpLink = errorLink.concat(httpLink);

  const apolloClient = new ApolloClient({
    name: "web",
    version: process.env.CLIENT_VERSION,
    connectToDevTools: process.env.NODE_ENV === "development",
    link: ApolloLink.from([authMiddleware, errorHttpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        User: {
          fields: {
            playlists: relayStylePagination(),
            pathways: relayStylePagination(),
            tasks: relayStylePagination(),
          },
        },
        Request: {
          keyFields: ["status", "id"],
        },
      },
      possibleTypes: {
        ProductType: ["Book", "Event", "OnlineCourse", "InternalEvent"],
      },
    }).restore(window.__APOLLO_STATE__),
  });

  clients[uri] = apolloClient;

  return apolloClient;
}
