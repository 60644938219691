import { faFlagCheckered, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import { NavLink, Link } from "react-router-dom";
import classNames from "classnames";

import { Text } from "@learnerbly/web-components";

import { NavLinkObject } from "./get-nav-links";
import Logo from "src/shared/components/logo";
import OffPlatformResourceButton from "src/shared/components/off-platform-resource-button";
import ProfileNavigation from "src/shared/components/profile-navigation-widget";
import RequestAnythingButton from "src/shared/components/request-anything-button";
import SearchForm from "src/shared/components//search-form";

import styles from "./styles.scss";

interface PageProps {
  to: string;
  children: string | React.ReactNode;
  exact?: boolean;
  isActive?: () => boolean;
  onClick: () => void;
}
const PageLink: FunctionComponent<PageProps> = ({
  to,
  children,
  exact = false,
  isActive,
  onClick,
}) => (
  <>
    <NavLink
      activeClassName={styles.active}
      className={styles.link}
      to={to}
      exact={exact}
      isActive={isActive}
      onClick={onClick}
    >
      <div className={styles.linktext}>{children}</div>
    </NavLink>
  </>
);

export interface HeaderProps {
  collapseLinksEarly: boolean;
  formattedBudgetWithDecimals: string;
  hasAdHocRequests: boolean;
  hasPotsEnabled: boolean;
  hasStartedPDP: boolean;
  isBudgetEmpty: boolean;
  isDesktop: boolean;
  lastDayOfBudget: string;
  logo: string;
  openGoals: Array<{ id: string; status: string; dueDate: string }>;
  orgPageUrl: string;
  navLinks: Array<NavLinkObject>;
  showNavigation: "hamburger" | "default";
  setShowNavigation: (navigationtype: "hamburger" | "default") => void;
  trackClick: (arg: string) => void;
}
export const Header: FunctionComponent<HeaderProps> = ({
  collapseLinksEarly,
  formattedBudgetWithDecimals,
  hasAdHocRequests,
  hasPotsEnabled,
  hasStartedPDP,
  isBudgetEmpty,
  isDesktop,
  lastDayOfBudget,
  logo,
  navLinks,
  openGoals,
  showNavigation,
  setShowNavigation,
  trackClick,
}) => {
  return (
    <header className={styles.header}>
      <nav
        aria-label="navigation"
        className={classNames(
          styles.navigation,
          collapseLinksEarly && styles.collapseLinksEarly,
        )}
      >
        <div data-cy="logo" className={styles.left}>
          <FontAwesomeIcon
            onClick={() =>
              setShowNavigation(
                showNavigation === "hamburger" ? "default" : "hamburger",
              )
            }
            className={styles.menu}
            icon={faBars}
          />
        </div>
        <div className={styles.middle}>
          <div className={styles.logo}>
            <Link
              to="/"
              aria-hidden={isDesktop}
              tabIndex={isDesktop ? -1 : 0}
              aria-label="Learnerbly home"
            >
              {logo ? <img src={logo} alt="logo" /> : <Logo />}
            </Link>
          </div>
          <div className={styles.links}>
            {navLinks.map(({ label, ...props }, index) => (
              <PageLink key={index} {...props}>
                {label}
              </PageLink>
            ))}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.search}>
            <SearchForm />
          </div>

          {hasAdHocRequests ? (
            <div className={styles.requestButton}>
              <RequestAnythingButton />
            </div>
          ) : (
            <div className={styles.requestButton}>
              <OffPlatformResourceButton trackClick={trackClick} />
            </div>
          )}

          {hasStartedPDP && (
            <Link to="/goals/" className={styles.goals}>
              <FontAwesomeIcon
                icon={faFlagCheckered}
                className={styles.goalIcon}
              />
              <Text textStyle="small" textVariant="bold">
                <span className={styles.goalsLabel}>
                  {`${openGoals.length} goal${
                    openGoals.length !== 1 ? "s" : ""
                  }`}
                </span>
              </Text>
            </Link>
          )}
          {!hasPotsEnabled && (
            <div className={styles.budgetContainer}>
              {formattedBudgetWithDecimals && (
                <div className={styles.budget}>
                  <Text textStyle="small" textVariant="bold">
                    {formattedBudgetWithDecimals}
                  </Text>
                </div>
              )}
              <div className={styles.budgetOverlay}>
                <Text textStyle="small">
                  {isBudgetEmpty
                    ? "You have no budget left to spend 😥"
                    : `You have ${formattedBudgetWithDecimals} left to spend until ${lastDayOfBudget} 😄`}
                </Text>
              </div>
            </div>
          )}
          <ProfileNavigation />
        </div>
      </nav>
    </header>
  );
};

export default Header;
