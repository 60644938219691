import classNames from "classnames";
import styles from "./styles.scss";
import { FunctionComponent } from "react";

interface LoadingProps {
  heightMode?: "fill" | "shrink" | "default";
}

const Loading: FunctionComponent<LoadingProps> = ({
  heightMode = "default",
}) => {
  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles.fillHeight]: heightMode === "fill",
          [styles.shrinkHeight]: heightMode === "shrink",
        })}
        data-testid="loading-component"
      >
        <div className={styles.bounce1} />
        <div className={styles.bounce2} />
        <div className={styles.bounce3} />
      </div>
    </>
  );
};

export default Loading;
