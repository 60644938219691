import { useMemo, useState } from "react";
import { AIResourceProvider } from "../providers/AIResourceProvider";
import { AlgoliaResourceProvider } from "../providers/AlgoliaResourceProvider";
import { ResourceProvider } from "../providers/ResourceProvider";
import { createApolloClient } from "src/shared/util/apollo";
import { ResourceProviderDecorator } from "../providers/ResourceProviderDecorator";
import { CurrentUser } from "../types";

interface UseProviderSearchCommandProps {
  options: { search?: string; ai?: boolean };
  config: { currentUser: CurrentUser };
}

export const useProviderSearchCommand = (
  { ai = false }: UseProviderSearchCommandProps["options"],
  { currentUser }: UseProviderSearchCommandProps["config"],
): [ResourceProvider, boolean] => {
  const [apiUrl] = useState(process.env.GATEWAY_API_URL as string);
  const [providerLoading, setProviderLoading] = useState(false);
  const apolloClient = useMemo(() => createApolloClient(apiUrl), [apiUrl]);

  const provider: ResourceProvider = useMemo(() => {
    const strategy = ai
      ? new AIResourceProvider(apolloClient, currentUser)
      : new AlgoliaResourceProvider(currentUser);

    return new ResourceProviderDecorator(strategy, setProviderLoading);
  }, [ai, currentUser, apolloClient]);

  return [provider, providerLoading];
};
