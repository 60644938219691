// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YjPVIBIsFgG2EY6go2xh{margin:70px 0 0 0}\n", "",{"version":3,"sources":["webpack://./src/shared/components/not-found-page/styles.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAkB","sourcesContent":[".noNavigation {\n  margin: 70px 0 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noNavigation": "YjPVIBIsFgG2EY6go2xh"
};
export default ___CSS_LOADER_EXPORT___;
