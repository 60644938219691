import "core-js/stable";
import "regenerator-runtime/runtime";

import { registerLocale } from "@learnerbly/i18n-iso-countries";
import { createRoot } from "react-dom/client";
import Auth from "@aws-amplify/auth";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import Entry from "./entry";
import { createBrowserHistory } from "history";

registerLocale(require("@learnerbly/i18n-iso-countries/langs/en.json"));

const history = createBrowserHistory();

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.SENTRY_PROJECT,
    environment: process.env.AWS_ENV,
    captureUnhandledRejections: true,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.2,
  });
}

// needs to be configured before we create apollo client
Auth.configure({
  identityPoolId: process.env.AWS_IDENTITY_POOL_ID,
  region: process.env.AWS_REGION,
  userPoolId: process.env.AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.AWS_USER_POOL_WEB_MAIN_CLIENT_ID,
});

window.addEventListener("load", () => {
  const root = createRoot(document.getElementById("root"));
  root.render(<Entry history={history} />);
});
