import { FunctionComponent, createContext, useState, useMemo } from "react";
import { ApolloProvider } from "@apollo/client";
import { createApolloClient } from "./shared/util/apollo";

interface ApolloContextData {
  apiUrl: string;
  setApiUrl: (apiUrl: string) => void;
}

export const ApolloContext = createContext<ApolloContextData>({
  apiUrl: process.env.GATEWAY_API_URL as string,
  setApiUrl: () => null,
});

export const ApolloContextWrapper: FunctionComponent = ({ children }) => {
  const [apiUrl, setApiUrl] = useState(process.env.GATEWAY_API_URL as string);

  const apolloClient = useMemo(() => createApolloClient(apiUrl), [apiUrl]);

  return (
    <ApolloContext.Provider value={{ apiUrl, setApiUrl }}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </ApolloContext.Provider>
  );
};
