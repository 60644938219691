import { useRef } from "react";
import { TextButton } from "@learnerbly/web-components";
import SearchCommand from "../search-command";
import styles from "./experiment-search-form-styles.scss";
import { useOSKeyCommand } from "../search-command/hooks/useOSKeyCommand";

export default function ExperimentSearchForm(): JSX.Element {
  const ref = useRef<{ open: () => void }>(null);
  const [keyCommand] = useOSKeyCommand();

  const onClick = () => {
    ref.current?.open();
  };
  return (
    <div className={styles.experimentContainer}>
      <SearchCommand ref={ref} />

      <TextButton onClick={onClick}>
        <div className={styles.searchButton}>
          <span className={styles.searchButtonText}>Search...</span>
          <div className={styles.searchButtonKbd}>
            <kbd>{keyCommand}</kbd>
            <kbd>K</kbd>
          </div>
        </div>
      </TextButton>
    </div>
  );
}
