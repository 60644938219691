import classNames from "classnames";

import styles from "./styles.scss";
import { useOSKeyCommand } from "../../../hooks/useOSKeyCommand";

interface AICheckboxButtonProps {
  value: boolean;
  onChange: (active: boolean) => void;
}

export const AICheckboxButton = (props: AICheckboxButtonProps): JSX.Element => {
  const [keyCommand] = useOSKeyCommand();

  return (
    <button
      className={classNames(styles.askAIButton, {
        [styles.active]: props.value,
      })}
      onClick={() => props.onChange(!props.value)}
    >
      <svg role="graphics-symbol" viewBox="0 0 16 16" className="sparkles">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.2757 4.82358C7.57934 4.71847 7.57934 4.53161 7.2757 4.41483L5.62905 3.78419C5.33709 3.67908 4.99842 3.3404 4.88164 3.03676L4.25101 1.39009C4.1459 1.08644 3.95905 1.08644 3.84226 1.39009L3.21163 3.03676C3.10653 3.32872 2.76786 3.6674 2.46422 3.78419L0.817572 4.41483C0.513934 4.51994 0.513934 4.70679 0.817572 4.82358L2.46422 5.45422C2.75618 5.55933 3.09485 5.898 3.21163 6.20165L3.84226 7.84832C3.94737 8.15196 4.13422 8.15196 4.25101 7.84832L4.88164 6.20165C4.98674 5.90968 5.32541 5.571 5.62905 5.45422L7.2757 4.82358ZM15.2991 10.5929C16.2334 10.3593 16.2334 9.9739 15.2991 9.74032L13.2321 9.22647C12.2978 8.9929 11.3402 8.03526 11.1066 7.10097L10.5928 5.03387C10.3592 4.09959 9.97382 4.09959 9.74025 5.03387L9.2264 7.10097C8.99283 8.03526 8.03521 8.9929 7.10094 9.22647L5.03387 9.74032C4.09961 9.9739 4.09961 10.3593 5.03387 10.5929L7.10094 11.1067C8.03521 11.3403 8.99283 12.2979 9.2264 13.2322L9.74025 15.2993C9.97382 16.2336 10.3592 16.2336 10.5928 15.2993L11.1066 13.2322C11.3402 12.2979 12.2978 11.3403 13.2321 11.1067L15.2991 10.5929Z"
        ></path>
      </svg>
      <span>Enable AI</span>
      <div className={styles.commandFooterKeys}>
        <kbd>{keyCommand}</kbd>
        <kbd>I</kbd>
      </div>
    </button>
  );
};
