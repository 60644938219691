import PropTypes from "prop-types";
import { FunctionComponent } from "react";

import styles from "./styles.scss";

const Form: FunctionComponent<{
  name: string;
  onSubmit(event: React.FormEvent<HTMLFormElement>): void;
  title?: string;
}> = ({ title, children, name, onSubmit, ...props }) => (
  <form name={name} onSubmit={onSubmit} {...props}>
    {title && <h1 className={styles.title}>{title}</h1>}
    {children}
  </form>
);

Form.propTypes = {
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default Form;
