/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query GetOrganisationBudgetPeriods {\n    organisation {\n      id\n      budgetPeriods {\n        id\n        archived\n        startDate\n        endDate\n      }\n    }\n  }\n": types.GetOrganisationBudgetPeriodsDocument,
    "\n  query GetAnalyticsDashboardUrl($identifier: String!) {\n    analyticsDashboard(input: { identifier: $identifier }) {\n      __typename\n      ... on MetabaseDashboard {\n        url\n        expiresAt\n      }\n      ... on NotFoundError {\n        message\n      }\n    }\n  }\n": types.GetAnalyticsDashboardUrlDocument,
    "\n  query OrganisationAnalytics {\n    organisationAnalytics {\n      pathways {\n        publishedCount\n        creatorCount\n        uniqueLearnersStartedAndCompletedCount\n        startedCount\n        completedCount\n      }\n    }\n  }\n": types.OrganisationAnalyticsDocument,
    "\n  fragment CoreGroupFields on Group {\n    id\n    name\n  }\n": types.CoreGroupFieldsFragmentDoc,
    "\n  mutation CreateGroup($group: GroupRequest!) {\n    createGroup(group: $group) {\n      id\n      name\n      organisation {\n        id\n        groups {\n          ...CoreGroupFields\n        }\n      }\n    }\n  }\n": types.CreateGroupDocument,
    "\n  query organisationGroups($showMemberCount: Boolean! = true) {\n    organisation {\n      id\n      name\n      groups {\n        id\n        name\n        memberCount @include(if: $showMemberCount)\n      }\n    }\n  }\n": types.OrganisationGroupsDocument,
    "\n  query MemberDataExport {\n    organisation {\n      dataExports {\n        activeUsers {\n          url\n        }\n      }\n    }\n  }\n": types.MemberDataExportDocument,
    "\n  mutation createValidatedPot(\n    $name: String!\n    $approvers: [ID!]\n    $budgetId: String\n    $allocatedFunds: Float\n    $consumers: [ID!]\n  ) {\n    createPot: createValidatedPot(\n      pot: {\n        name: $name\n        approvers: $approvers\n        budgetId: $budgetId\n        allocatedFunds: $allocatedFunds\n        consumers: $consumers\n      }\n    ) {\n      ... on Pot {\n        id\n        currency\n      }\n      ... on PotNameDuplicationError {\n        message\n      }\n    }\n  }\n": types.CreateValidatedPotDocument,
    "\n  query RequestsDataExport {\n    organisation {\n      id\n      dataExports {\n        requests {\n          url\n        }\n      }\n    }\n  }\n": types.RequestsDataExportDocument,
    "\n  mutation CompletePathwayAction($pathwayId: ID!, $actionId: ID!) {\n    completePathwayAction(pathwayId: $pathwayId, actionId: $actionId) {\n      ... on LearningPathway {\n        id\n        userProgression {\n          status\n          actions {\n            id\n            status\n          }\n        }\n      }\n    }\n  }\n": types.CompletePathwayActionDocument,
    "\n  mutation DeleteAction($pathwayId: ID!, $actionId: ID!) {\n    deletePathwayAction(pathwayId: $pathwayId, actionId: $actionId) {\n      __typename\n      ... on LearningPathway {\n        id\n        actions {\n          id\n        }\n      }\n      ... on UserError {\n        message\n      }\n    }\n  }\n": types.DeleteActionDocument,
    "\n  mutation CreateLearningPathwayAsync(\n    $prompts: PathwayPrompts!\n    $title: String\n  ) {\n    learningPathway: createLearningPathway(prompts: $prompts, title: $title)\n  }\n": types.CreateLearningPathwayAsyncDocument,
    "\n  mutation GenerateLearningPathway($prompts: PathwayPrompts!, $title: String) {\n    learningPathway: generateLearningPathway(prompts: $prompts, title: $title) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        description\n        status\n        actions {\n          id\n          type\n          name\n          resource {\n            id\n            title\n            image1x1\n            isInternalContent\n            supplier {\n              id\n              name\n            }\n            type {\n              id\n              name\n            }\n          }\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n": types.GenerateLearningPathwayDocument,
    "\n  query GetPathwayForReviewById($id: ID!) {\n    pathway(id: $id) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        description\n        status\n        actions {\n          id\n          type\n          name\n          resource {\n            id\n            title\n            image1x1\n            isInternalContent\n            supplier {\n              id\n              name\n            }\n            type {\n              id\n              name\n            }\n          }\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n": types.GetPathwayForReviewByIdDocument,
    "\n  mutation PublishPathwayAfterGeneration($pathwayId: ID!) {\n    learningPathway: publishLearningPathway(pathwayId: $pathwayId) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        description\n        status\n        userProgression {\n          status\n        }\n        updatedAt\n        actions {\n          name\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n": types.PublishPathwayAfterGenerationDocument,
    "\n  mutation UpdatePathwayAfterGeneration($input: PathwayInput!) {\n    learningPathway: updateLearningPathway(pathway: $input) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        description\n        userProgression {\n          status\n        }\n        updatedAt\n        actions {\n          name\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n": types.UpdatePathwayAfterGenerationDocument,
    "\n  query LearnerPathwaysInsights {\n    user {\n      id\n      pathwaysAnalytics {\n        publishedCount\n        uniqueLearnersStartedAndCompletedCount\n        startedCount\n        completedCount\n      }\n    }\n  }\n": types.LearnerPathwaysInsightsDocument,
    "\n  query GetPathwayById($id: ID!) {\n    pathway(id: $id) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        userProgression {\n          status\n          actions {\n            id\n            status\n          }\n        }\n        description\n        createdBy {\n          id\n          firstName\n          lastName\n        }\n        actions {\n          id\n          type\n          name\n          resource {\n            id\n            title\n            image1x1\n            isInternalContent\n            supplier {\n              id\n              name\n            }\n            type {\n              id\n              name\n            }\n          }\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n": types.GetPathwayByIdDocument,
    "\n  mutation SharePathway($pathwayId: ID!, $recipientIds: [ID!]!, $note: String) {\n    sharePathway(\n      pathwayId: $pathwayId\n      recipientIds: $recipientIds\n      note: $note\n    ) {\n      __typename\n      ... on LearningPathway {\n        id\n      }\n      ... on UserError {\n        message\n      }\n    }\n  }\n": types.SharePathwayDocument,
    "\n  mutation StartLearningPathway($pathwayId: ID!) {\n    startLearningPathway(pathwayId: $pathwayId) {\n      ... on LearningPathway {\n        id\n        userProgression {\n          status\n          actions {\n            id\n            status\n          }\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n": types.StartLearningPathwayDocument,
    "\n  query GetUserInProgressPathways($first: Int, $after: Cursor) {\n    user {\n      id\n      inProgressPathways(first: $first, after: $after) {\n        edges {\n          cursor\n          node {\n            ... on LearningPathway {\n              id\n              title\n              userProgression {\n                createdAt\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GetUserInProgressPathwaysDocument,
    "\n  query GetUserCreatedPathways($first: Int, $after: Cursor) {\n    user {\n      id\n      pathways(first: $first, after: $after) {\n        edges {\n          cursor\n          node {\n            ... on LearningPathway {\n              id\n              title\n            }\n          }\n        }\n        totalCount\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n": types.GetUserCreatedPathwaysDocument,
    "\n  query GetCurrentUserSkills {\n    user {\n      id\n      learnerSkills {\n        name\n      }\n    }\n  }\n": types.GetCurrentUserSkillsDocument,
    "\n  mutation EditResourceComment(\n    $comment: String!\n    $playlistId: ID!\n    $resourceId: ID!\n  ) {\n    editResourceComment(\n      comment: $comment\n      playlistId: $playlistId\n      resourceId: $resourceId\n    ) {\n      id\n      paginatedEntries(playlistId: $playlistId) {\n        edges {\n          node {\n            comment\n            resource {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n": types.EditResourceCommentDocument,
    "\n  mutation RemoveResource($playlistId: ID!, $resourceId: ID!) {\n    removeResourceFromPlaylist(\n      playlistId: $playlistId\n      resourceId: $resourceId\n    ) {\n      id\n      paginatedEntries(playlistId: $playlistId) {\n        edges {\n          node {\n            comment\n            resource {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n": types.RemoveResourceDocument,
    "\n  mutation UpdateResourceIndexes(\n    $playlistId: ID!\n    $playlistFields: UpdatePlaylistFieldsRequest!\n  ) {\n    updatePlaylistFields(\n      playlistFields: $playlistFields\n      playlistId: $playlistId\n    ) {\n      id\n      paginatedEntries(playlistId: $playlistId) {\n        edges {\n          node {\n            comment\n            index\n            resource {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n": types.UpdateResourceIndexesDocument,
    "\n  mutation CreateRequestByType($request: CreateRequestInput!) {\n    createRequestByType(request: $request) {\n      id\n      status\n    }\n  }\n": types.CreateRequestByTypeDocument,
    "\n  query GetResourceTypesForFulfilledByLearnerRequest {\n    resourceTypes {\n      id\n      name\n    }\n  }\n": types.GetResourceTypesForFulfilledByLearnerRequestDocument,
    "\n  mutation CreateUserSession($email: String!, $password: String!) {\n    sessionToken: createSession(email: $email, password: $password)\n  }\n": types.CreateUserSessionDocument,
    "\n  mutation SendMagicLinkEmail($email: String!) {\n    sendMagicLinkEmail(email: $email) {\n      ... on MagicLinkEmail {\n        success\n      }\n      ... on InvalidInputError {\n        message\n      }\n    }\n  }\n": types.SendMagicLinkEmailDocument,
    "\n  query SearchAllContent($query: String!, $size: Int) {\n    searchAllContent(query: $query, size: $size) {\n      ... on Resource {\n        id\n        title\n      }\n      ... on Playlist {\n        id\n        title\n      }\n    }\n  }\n": types.SearchAllContentDocument,
    "\n  query FirstOrganisationMembers($query: String, $from: Int, $size: Int) {\n    organisation {\n      id\n      membersFrom(query: $query, from: $from, size: $size) {\n        results {\n          ... on User {\n            id\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  }\n": types.FirstOrganisationMembersDocument,
    "\n  query SearchOrganisationMembers($query: String!, $searchAfter: String) {\n    searchOrganisationMembers(\n      query: $query\n      searchAfter: $searchAfter\n      size: 10\n    ) {\n      searchAfter\n      results {\n        ... on User {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n": types.SearchOrganisationMembersDocument,
    "\n  query IntelligentSearchResources($query: String!, $currency: CurrencyCode!) {\n    intelligentSearchResources(query: $query, currency: $currency) {\n      id\n      title\n      image1x1\n      supplier {\n        id\n        name\n      }\n      type {\n        id\n        name\n      }\n      lowestPrice {\n        amount\n        currency\n      }\n    }\n  }\n": types.IntelligentSearchResourcesDocument,
    "\n  query SearchSkills($query: String!) {\n    searchSkills(query: $query) {\n      name\n    }\n  }\n": types.SearchSkillsDocument,
    "\n  mutation DismissFeedback($requestId: ID!) {\n    dismissFeedback(requestId: $requestId) {\n      requestId\n    }\n  }\n": types.DismissFeedbackDocument,
    "\n  query requestForSkillsFeedback($first: Int) {\n    requestForSkillsFeedback {\n      id\n      status\n      resource {\n        id\n        title\n        skills(first: $first) {\n          edges {\n            node {\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n": types.RequestForSkillsFeedbackDocument,
    "\n  mutation SaveSkillsFeedback(\n    $requestId: ID!\n    $skills: [String]!\n    $customSkills: [String]!\n  ) {\n    saveFeedback(\n      requestId: $requestId\n      skills: $skills\n      customSkills: $customSkills\n    ) {\n      requestId\n    }\n  }\n": types.SaveSkillsFeedbackDocument,
    "\n  mutation SaveLearnerSkills($learnerId: ID!, $skills: [String!]!) {\n    saveLearnerSkills(learnerId: $learnerId, skills: $skills) {\n      __typename\n      id\n      learnerSkills {\n        learnerId\n        name\n        assignedBy\n      }\n    }\n  }\n": types.SaveLearnerSkillsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetOrganisationBudgetPeriods {\n    organisation {\n      id\n      budgetPeriods {\n        id\n        archived\n        startDate\n        endDate\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetOrganisationBudgetPeriods {\n    organisation {\n      id\n      budgetPeriods {\n        id\n        archived\n        startDate\n        endDate\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAnalyticsDashboardUrl($identifier: String!) {\n    analyticsDashboard(input: { identifier: $identifier }) {\n      __typename\n      ... on MetabaseDashboard {\n        url\n        expiresAt\n      }\n      ... on NotFoundError {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAnalyticsDashboardUrl($identifier: String!) {\n    analyticsDashboard(input: { identifier: $identifier }) {\n      __typename\n      ... on MetabaseDashboard {\n        url\n        expiresAt\n      }\n      ... on NotFoundError {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrganisationAnalytics {\n    organisationAnalytics {\n      pathways {\n        publishedCount\n        creatorCount\n        uniqueLearnersStartedAndCompletedCount\n        startedCount\n        completedCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query OrganisationAnalytics {\n    organisationAnalytics {\n      pathways {\n        publishedCount\n        creatorCount\n        uniqueLearnersStartedAndCompletedCount\n        startedCount\n        completedCount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CoreGroupFields on Group {\n    id\n    name\n  }\n"): (typeof documents)["\n  fragment CoreGroupFields on Group {\n    id\n    name\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateGroup($group: GroupRequest!) {\n    createGroup(group: $group) {\n      id\n      name\n      organisation {\n        id\n        groups {\n          ...CoreGroupFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateGroup($group: GroupRequest!) {\n    createGroup(group: $group) {\n      id\n      name\n      organisation {\n        id\n        groups {\n          ...CoreGroupFields\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query organisationGroups($showMemberCount: Boolean! = true) {\n    organisation {\n      id\n      name\n      groups {\n        id\n        name\n        memberCount @include(if: $showMemberCount)\n      }\n    }\n  }\n"): (typeof documents)["\n  query organisationGroups($showMemberCount: Boolean! = true) {\n    organisation {\n      id\n      name\n      groups {\n        id\n        name\n        memberCount @include(if: $showMemberCount)\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MemberDataExport {\n    organisation {\n      dataExports {\n        activeUsers {\n          url\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query MemberDataExport {\n    organisation {\n      dataExports {\n        activeUsers {\n          url\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createValidatedPot(\n    $name: String!\n    $approvers: [ID!]\n    $budgetId: String\n    $allocatedFunds: Float\n    $consumers: [ID!]\n  ) {\n    createPot: createValidatedPot(\n      pot: {\n        name: $name\n        approvers: $approvers\n        budgetId: $budgetId\n        allocatedFunds: $allocatedFunds\n        consumers: $consumers\n      }\n    ) {\n      ... on Pot {\n        id\n        currency\n      }\n      ... on PotNameDuplicationError {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createValidatedPot(\n    $name: String!\n    $approvers: [ID!]\n    $budgetId: String\n    $allocatedFunds: Float\n    $consumers: [ID!]\n  ) {\n    createPot: createValidatedPot(\n      pot: {\n        name: $name\n        approvers: $approvers\n        budgetId: $budgetId\n        allocatedFunds: $allocatedFunds\n        consumers: $consumers\n      }\n    ) {\n      ... on Pot {\n        id\n        currency\n      }\n      ... on PotNameDuplicationError {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query RequestsDataExport {\n    organisation {\n      id\n      dataExports {\n        requests {\n          url\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query RequestsDataExport {\n    organisation {\n      id\n      dataExports {\n        requests {\n          url\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CompletePathwayAction($pathwayId: ID!, $actionId: ID!) {\n    completePathwayAction(pathwayId: $pathwayId, actionId: $actionId) {\n      ... on LearningPathway {\n        id\n        userProgression {\n          status\n          actions {\n            id\n            status\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CompletePathwayAction($pathwayId: ID!, $actionId: ID!) {\n    completePathwayAction(pathwayId: $pathwayId, actionId: $actionId) {\n      ... on LearningPathway {\n        id\n        userProgression {\n          status\n          actions {\n            id\n            status\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteAction($pathwayId: ID!, $actionId: ID!) {\n    deletePathwayAction(pathwayId: $pathwayId, actionId: $actionId) {\n      __typename\n      ... on LearningPathway {\n        id\n        actions {\n          id\n        }\n      }\n      ... on UserError {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteAction($pathwayId: ID!, $actionId: ID!) {\n    deletePathwayAction(pathwayId: $pathwayId, actionId: $actionId) {\n      __typename\n      ... on LearningPathway {\n        id\n        actions {\n          id\n        }\n      }\n      ... on UserError {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateLearningPathwayAsync(\n    $prompts: PathwayPrompts!\n    $title: String\n  ) {\n    learningPathway: createLearningPathway(prompts: $prompts, title: $title)\n  }\n"): (typeof documents)["\n  mutation CreateLearningPathwayAsync(\n    $prompts: PathwayPrompts!\n    $title: String\n  ) {\n    learningPathway: createLearningPathway(prompts: $prompts, title: $title)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation GenerateLearningPathway($prompts: PathwayPrompts!, $title: String) {\n    learningPathway: generateLearningPathway(prompts: $prompts, title: $title) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        description\n        status\n        actions {\n          id\n          type\n          name\n          resource {\n            id\n            title\n            image1x1\n            isInternalContent\n            supplier {\n              id\n              name\n            }\n            type {\n              id\n              name\n            }\n          }\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation GenerateLearningPathway($prompts: PathwayPrompts!, $title: String) {\n    learningPathway: generateLearningPathway(prompts: $prompts, title: $title) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        description\n        status\n        actions {\n          id\n          type\n          name\n          resource {\n            id\n            title\n            image1x1\n            isInternalContent\n            supplier {\n              id\n              name\n            }\n            type {\n              id\n              name\n            }\n          }\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPathwayForReviewById($id: ID!) {\n    pathway(id: $id) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        description\n        status\n        actions {\n          id\n          type\n          name\n          resource {\n            id\n            title\n            image1x1\n            isInternalContent\n            supplier {\n              id\n              name\n            }\n            type {\n              id\n              name\n            }\n          }\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPathwayForReviewById($id: ID!) {\n    pathway(id: $id) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        description\n        status\n        actions {\n          id\n          type\n          name\n          resource {\n            id\n            title\n            image1x1\n            isInternalContent\n            supplier {\n              id\n              name\n            }\n            type {\n              id\n              name\n            }\n          }\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation PublishPathwayAfterGeneration($pathwayId: ID!) {\n    learningPathway: publishLearningPathway(pathwayId: $pathwayId) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        description\n        status\n        userProgression {\n          status\n        }\n        updatedAt\n        actions {\n          name\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation PublishPathwayAfterGeneration($pathwayId: ID!) {\n    learningPathway: publishLearningPathway(pathwayId: $pathwayId) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        description\n        status\n        userProgression {\n          status\n        }\n        updatedAt\n        actions {\n          name\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdatePathwayAfterGeneration($input: PathwayInput!) {\n    learningPathway: updateLearningPathway(pathway: $input) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        description\n        userProgression {\n          status\n        }\n        updatedAt\n        actions {\n          name\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdatePathwayAfterGeneration($input: PathwayInput!) {\n    learningPathway: updateLearningPathway(pathway: $input) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        description\n        userProgression {\n          status\n        }\n        updatedAt\n        actions {\n          name\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query LearnerPathwaysInsights {\n    user {\n      id\n      pathwaysAnalytics {\n        publishedCount\n        uniqueLearnersStartedAndCompletedCount\n        startedCount\n        completedCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query LearnerPathwaysInsights {\n    user {\n      id\n      pathwaysAnalytics {\n        publishedCount\n        uniqueLearnersStartedAndCompletedCount\n        startedCount\n        completedCount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPathwayById($id: ID!) {\n    pathway(id: $id) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        userProgression {\n          status\n          actions {\n            id\n            status\n          }\n        }\n        description\n        createdBy {\n          id\n          firstName\n          lastName\n        }\n        actions {\n          id\n          type\n          name\n          resource {\n            id\n            title\n            image1x1\n            isInternalContent\n            supplier {\n              id\n              name\n            }\n            type {\n              id\n              name\n            }\n          }\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPathwayById($id: ID!) {\n    pathway(id: $id) {\n      ... on LearningPathway {\n        __typename\n        id\n        title\n        userProgression {\n          status\n          actions {\n            id\n            status\n          }\n        }\n        description\n        createdBy {\n          id\n          firstName\n          lastName\n        }\n        actions {\n          id\n          type\n          name\n          resource {\n            id\n            title\n            image1x1\n            isInternalContent\n            supplier {\n              id\n              name\n            }\n            type {\n              id\n              name\n            }\n          }\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SharePathway($pathwayId: ID!, $recipientIds: [ID!]!, $note: String) {\n    sharePathway(\n      pathwayId: $pathwayId\n      recipientIds: $recipientIds\n      note: $note\n    ) {\n      __typename\n      ... on LearningPathway {\n        id\n      }\n      ... on UserError {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SharePathway($pathwayId: ID!, $recipientIds: [ID!]!, $note: String) {\n    sharePathway(\n      pathwayId: $pathwayId\n      recipientIds: $recipientIds\n      note: $note\n    ) {\n      __typename\n      ... on LearningPathway {\n        id\n      }\n      ... on UserError {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation StartLearningPathway($pathwayId: ID!) {\n    startLearningPathway(pathwayId: $pathwayId) {\n      ... on LearningPathway {\n        id\n        userProgression {\n          status\n          actions {\n            id\n            status\n          }\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation StartLearningPathway($pathwayId: ID!) {\n    startLearningPathway(pathwayId: $pathwayId) {\n      ... on LearningPathway {\n        id\n        userProgression {\n          status\n          actions {\n            id\n            status\n          }\n        }\n      }\n      ... on UserError {\n        __typename\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetUserInProgressPathways($first: Int, $after: Cursor) {\n    user {\n      id\n      inProgressPathways(first: $first, after: $after) {\n        edges {\n          cursor\n          node {\n            ... on LearningPathway {\n              id\n              title\n              userProgression {\n                createdAt\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUserInProgressPathways($first: Int, $after: Cursor) {\n    user {\n      id\n      inProgressPathways(first: $first, after: $after) {\n        edges {\n          cursor\n          node {\n            ... on LearningPathway {\n              id\n              title\n              userProgression {\n                createdAt\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetUserCreatedPathways($first: Int, $after: Cursor) {\n    user {\n      id\n      pathways(first: $first, after: $after) {\n        edges {\n          cursor\n          node {\n            ... on LearningPathway {\n              id\n              title\n            }\n          }\n        }\n        totalCount\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUserCreatedPathways($first: Int, $after: Cursor) {\n    user {\n      id\n      pathways(first: $first, after: $after) {\n        edges {\n          cursor\n          node {\n            ... on LearningPathway {\n              id\n              title\n            }\n          }\n        }\n        totalCount\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCurrentUserSkills {\n    user {\n      id\n      learnerSkills {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCurrentUserSkills {\n    user {\n      id\n      learnerSkills {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EditResourceComment(\n    $comment: String!\n    $playlistId: ID!\n    $resourceId: ID!\n  ) {\n    editResourceComment(\n      comment: $comment\n      playlistId: $playlistId\n      resourceId: $resourceId\n    ) {\n      id\n      paginatedEntries(playlistId: $playlistId) {\n        edges {\n          node {\n            comment\n            resource {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation EditResourceComment(\n    $comment: String!\n    $playlistId: ID!\n    $resourceId: ID!\n  ) {\n    editResourceComment(\n      comment: $comment\n      playlistId: $playlistId\n      resourceId: $resourceId\n    ) {\n      id\n      paginatedEntries(playlistId: $playlistId) {\n        edges {\n          node {\n            comment\n            resource {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RemoveResource($playlistId: ID!, $resourceId: ID!) {\n    removeResourceFromPlaylist(\n      playlistId: $playlistId\n      resourceId: $resourceId\n    ) {\n      id\n      paginatedEntries(playlistId: $playlistId) {\n        edges {\n          node {\n            comment\n            resource {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveResource($playlistId: ID!, $resourceId: ID!) {\n    removeResourceFromPlaylist(\n      playlistId: $playlistId\n      resourceId: $resourceId\n    ) {\n      id\n      paginatedEntries(playlistId: $playlistId) {\n        edges {\n          node {\n            comment\n            resource {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateResourceIndexes(\n    $playlistId: ID!\n    $playlistFields: UpdatePlaylistFieldsRequest!\n  ) {\n    updatePlaylistFields(\n      playlistFields: $playlistFields\n      playlistId: $playlistId\n    ) {\n      id\n      paginatedEntries(playlistId: $playlistId) {\n        edges {\n          node {\n            comment\n            index\n            resource {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateResourceIndexes(\n    $playlistId: ID!\n    $playlistFields: UpdatePlaylistFieldsRequest!\n  ) {\n    updatePlaylistFields(\n      playlistFields: $playlistFields\n      playlistId: $playlistId\n    ) {\n      id\n      paginatedEntries(playlistId: $playlistId) {\n        edges {\n          node {\n            comment\n            index\n            resource {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateRequestByType($request: CreateRequestInput!) {\n    createRequestByType(request: $request) {\n      id\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation CreateRequestByType($request: CreateRequestInput!) {\n    createRequestByType(request: $request) {\n      id\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetResourceTypesForFulfilledByLearnerRequest {\n    resourceTypes {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query GetResourceTypesForFulfilledByLearnerRequest {\n    resourceTypes {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateUserSession($email: String!, $password: String!) {\n    sessionToken: createSession(email: $email, password: $password)\n  }\n"): (typeof documents)["\n  mutation CreateUserSession($email: String!, $password: String!) {\n    sessionToken: createSession(email: $email, password: $password)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SendMagicLinkEmail($email: String!) {\n    sendMagicLinkEmail(email: $email) {\n      ... on MagicLinkEmail {\n        success\n      }\n      ... on InvalidInputError {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SendMagicLinkEmail($email: String!) {\n    sendMagicLinkEmail(email: $email) {\n      ... on MagicLinkEmail {\n        success\n      }\n      ... on InvalidInputError {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchAllContent($query: String!, $size: Int) {\n    searchAllContent(query: $query, size: $size) {\n      ... on Resource {\n        id\n        title\n      }\n      ... on Playlist {\n        id\n        title\n      }\n    }\n  }\n"): (typeof documents)["\n  query SearchAllContent($query: String!, $size: Int) {\n    searchAllContent(query: $query, size: $size) {\n      ... on Resource {\n        id\n        title\n      }\n      ... on Playlist {\n        id\n        title\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FirstOrganisationMembers($query: String, $from: Int, $size: Int) {\n    organisation {\n      id\n      membersFrom(query: $query, from: $from, size: $size) {\n        results {\n          ... on User {\n            id\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query FirstOrganisationMembers($query: String, $from: Int, $size: Int) {\n    organisation {\n      id\n      membersFrom(query: $query, from: $from, size: $size) {\n        results {\n          ... on User {\n            id\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchOrganisationMembers($query: String!, $searchAfter: String) {\n    searchOrganisationMembers(\n      query: $query\n      searchAfter: $searchAfter\n      size: 10\n    ) {\n      searchAfter\n      results {\n        ... on User {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SearchOrganisationMembers($query: String!, $searchAfter: String) {\n    searchOrganisationMembers(\n      query: $query\n      searchAfter: $searchAfter\n      size: 10\n    ) {\n      searchAfter\n      results {\n        ... on User {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query IntelligentSearchResources($query: String!, $currency: CurrencyCode!) {\n    intelligentSearchResources(query: $query, currency: $currency) {\n      id\n      title\n      image1x1\n      supplier {\n        id\n        name\n      }\n      type {\n        id\n        name\n      }\n      lowestPrice {\n        amount\n        currency\n      }\n    }\n  }\n"): (typeof documents)["\n  query IntelligentSearchResources($query: String!, $currency: CurrencyCode!) {\n    intelligentSearchResources(query: $query, currency: $currency) {\n      id\n      title\n      image1x1\n      supplier {\n        id\n        name\n      }\n      type {\n        id\n        name\n      }\n      lowestPrice {\n        amount\n        currency\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchSkills($query: String!) {\n    searchSkills(query: $query) {\n      name\n    }\n  }\n"): (typeof documents)["\n  query SearchSkills($query: String!) {\n    searchSkills(query: $query) {\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DismissFeedback($requestId: ID!) {\n    dismissFeedback(requestId: $requestId) {\n      requestId\n    }\n  }\n"): (typeof documents)["\n  mutation DismissFeedback($requestId: ID!) {\n    dismissFeedback(requestId: $requestId) {\n      requestId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query requestForSkillsFeedback($first: Int) {\n    requestForSkillsFeedback {\n      id\n      status\n      resource {\n        id\n        title\n        skills(first: $first) {\n          edges {\n            node {\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query requestForSkillsFeedback($first: Int) {\n    requestForSkillsFeedback {\n      id\n      status\n      resource {\n        id\n        title\n        skills(first: $first) {\n          edges {\n            node {\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SaveSkillsFeedback(\n    $requestId: ID!\n    $skills: [String]!\n    $customSkills: [String]!\n  ) {\n    saveFeedback(\n      requestId: $requestId\n      skills: $skills\n      customSkills: $customSkills\n    ) {\n      requestId\n    }\n  }\n"): (typeof documents)["\n  mutation SaveSkillsFeedback(\n    $requestId: ID!\n    $skills: [String]!\n    $customSkills: [String]!\n  ) {\n    saveFeedback(\n      requestId: $requestId\n      skills: $skills\n      customSkills: $customSkills\n    ) {\n      requestId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SaveLearnerSkills($learnerId: ID!, $skills: [String!]!) {\n    saveLearnerSkills(learnerId: $learnerId, skills: $skills) {\n      __typename\n      id\n      learnerSkills {\n        learnerId\n        name\n        assignedBy\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveLearnerSkills($learnerId: ID!, $skills: [String!]!) {\n    saveLearnerSkills(learnerId: $learnerId, skills: $skills) {\n      __typename\n      id\n      learnerSkills {\n        learnerId\n        name\n        assignedBy\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;