import { useContext } from "react";
import styles from "./styles.scss";
import { AppContext } from "src/app-context";
import { User } from "@sentry/browser";
import { formatCurrencyValue } from "src/shared/util/text-display";
import { CommandResource } from "../../types";
import LinkLabel from "src/shared/components/link-label";

interface CommandDialogResourceProps {
  resource: CommandResource;
}
export const CommandDialogResource = (
  props: CommandDialogResourceProps,
): JSX.Element => {
  const { currentUser } = useContext(AppContext) as { currentUser: User };
  const { resource } = props;

  const price = resource.lowestPrice?.amount
    ? formatCurrencyValue(
        resource.lowestPrice?.amount,
        resource.lowestPrice?.currency,
        currentUser.locale,
      )
    : null;

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        {resource.image1x1 && (
          <img
            className={styles.image}
            src={resource.image1x1}
            alt={resource.title}
          />
        )}
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <div className={styles.title}>{resource.title}</div>
          <div className={styles.label}>
            <LinkLabel type={resource.type.name} />
          </div>
        </div>

        <div className={styles.footer}>
          {
            <div className={styles.price}>
              {price && (
                <div>
                  <i>from</i> {price}
                </div>
              )}
            </div>
          }
          {
            <div className={styles.supplier}>
              {resource.supplier && resource.supplier.name}
            </div>
          }
        </div>
      </div>
    </div>
  );
};
