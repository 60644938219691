import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Command } from "cmdk";
import classNames from "classnames";
import { SearchCommandContext } from "../../contexts/SearchCommandContext";
import { CommanddialogFooter } from "../CommandDialogFooter";
import { CommandResource } from "../../types";
import { CommandDialogResource } from "../CommandDialogResource";
import Loading from "src/shared/components/loading";
import { AppContext } from "src/app-context";

import styles from "./styles.scss";

const DEBOUNCE_TIME = 1000;

interface CommandDialogProps {
  container: HTMLElement | undefined;
}

export const CommandDialog = (props: CommandDialogProps): JSX.Element => {
  const { setAlert } = useContext(AppContext);
  const searchCommandContext = useContext(SearchCommandContext);
  const [resources, setResources] = useState<Array<CommandResource>>([]);
  const [provider, setProvider] = useState(searchCommandContext.provider);
  const history = useHistory();

  // This function is used to fetch resources. The function is updated when the provider changes.
  const fetchResources = useCallback(
    async (query: string, debounce = 0) => {
      try {
        const resources = await searchCommandContext.provider?.query(
          query,
          debounce,
        );

        setResources(resources ?? []);
      } catch (err) {
        setAlert({ type: "error", message: `Failed to fetch resources`, err });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchCommandContext.provider],
  );

  // This effect is used to abort the query in case it's pending when the provider changes.
  useEffect(() => {
    provider?.abortQuery();
    setProvider(searchCommandContext.provider);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCommandContext.provider]);

  // This effect is used to fetch resources when the search query changes. The debounce will be 0 if the search query is empty and DEBOUNCE_TIME otherwise.
  useEffect(() => {
    fetchResources(
      searchCommandContext.search,
      !searchCommandContext.search ? 0 : DEBOUNCE_TIME,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCommandContext.search]);

  // This effect is used to fetch resources when the AI flag changes. The debounce time will be 0 in this case.
  useEffect(() => {
    fetchResources(searchCommandContext.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCommandContext.ai]);

  const openSelectItem = (value: string) => {
    searchCommandContext.setOpen(false);
    history.push(`/resources/${value}`);
  };

  return (
    <Command.Dialog
      shouldFilter={false}
      open={searchCommandContext.open}
      onOpenChange={searchCommandContext.setOpen}
      container={props.container}
      label="Global Command Menu"
      className={classNames({ [styles["ai-active"]]: searchCommandContext.ai })}
    >
      <Command.Input
        placeholder="Search for resources..."
        value={searchCommandContext.search}
        onValueChange={searchCommandContext.setSearch}
      />

      {searchCommandContext.providerLoading && (
        <Command.Loading>
          <div className={styles.loading}>
            <Loading />
          </div>
        </Command.Loading>
      )}
      <Command.List>
        <Command.Group heading="Resources">
          <Command.Empty>No results found.</Command.Empty>
          {resources.map((item) => (
            <Command.Item
              key={item.id}
              value={item.id}
              onSelect={openSelectItem}
            >
              <CommandDialogResource resource={item} />
            </Command.Item>
          ))}
        </Command.Group>
      </Command.List>

      <CommanddialogFooter />
    </Command.Dialog>
  );
};
