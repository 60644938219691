// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h6dvq_cM9EE_qXXRBxKr button:focus{outline:1px auto #005fcc;outline-offset:1px}.h6dvq_cM9EE_qXXRBxKr .M_9TeJzryz6Gn11fkcj9{background-color:#fff;border:1px solid #e1e5ed;border-radius:6.25rem;padding:0.4rem 1rem;width:200px;display:flex;justify-content:space-between}.h6dvq_cM9EE_qXXRBxKr .M_9TeJzryz6Gn11fkcj9 .B7cP3RwXqVnyB1QPK3L1{color:#b4bcce;font-weight:400}.h6dvq_cM9EE_qXXRBxKr .M_9TeJzryz6Gn11fkcj9 .TJox6oJJV5JYlOHGHKJX{display:flex;align-items:center;gap:4px}.h6dvq_cM9EE_qXXRBxKr .M_9TeJzryz6Gn11fkcj9 .TJox6oJJV5JYlOHGHKJX kbd{background:#f3f3f3;font-size:small;color:#6f6f6f;height:18px;width:18px;border-radius:4px;padding:0 4px}\n", "",{"version":3,"sources":["webpack://./src/shared/components/search-form/experiment-search-form-styles.scss"],"names":[],"mappings":"AAAA,mCAGM,wBAAiC,CACjC,kBAAmB,CAJzB,4CAQI,qBAAsB,CACtB,wBAAyB,CACzB,qBAAsB,CACtB,mBAAoB,CACpB,WAAY,CACZ,YAAa,CACb,6BAA8B,CAdlC,kEAiBM,aAAc,CACd,eAAgB,CAlBtB,kEAsBM,YAAa,CACb,kBAAmB,CACnB,OAAQ,CAxBd,sEA2BQ,kBAAmB,CACnB,eAAgB,CAChB,aAAc,CACd,WAAY,CACZ,UAAW,CACX,iBAAkB,CAClB,aAAc","sourcesContent":[".experimentContainer {\n  button {\n    &:focus {\n      outline: 1px auto rgb(0, 95, 204);\n      outline-offset: 1px;\n    }\n  }\n  .searchButton {\n    background-color: #fff;\n    border: 1px solid #e1e5ed;\n    border-radius: 6.25rem;\n    padding: 0.4rem 1rem;\n    width: 200px;\n    display: flex;\n    justify-content: space-between;\n\n    .searchButtonText {\n      color: #b4bcce;\n      font-weight: 400;\n    }\n\n    .searchButtonKbd {\n      display: flex;\n      align-items: center;\n      gap: 4px;\n\n      kbd {\n        background: #f3f3f3;\n        font-size: small;\n        color: #6f6f6f;\n        height: 18px;\n        width: 18px;\n        border-radius: 4px;\n        padding: 0 4px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"experimentContainer": "h6dvq_cM9EE_qXXRBxKr",
	"searchButton": "M_9TeJzryz6Gn11fkcj9",
	"searchButtonText": "B7cP3RwXqVnyB1QPK3L1",
	"searchButtonKbd": "TJox6oJJV5JYlOHGHKJX"
};
export default ___CSS_LOADER_EXPORT___;
