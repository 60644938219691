import "normalize.css";
import "numeral/locales/en-gb";

import "./shared/styles/index.scss";

import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import numeral from "numeral";

import { attachIntercom } from "./shared/util/intercom";

import Authorizer from "./shared/components/authorizer";
import State from "./shared/state";

import { createStore, combineReducers } from "redux";
import { reducer as form } from "redux-form";
import React, { FunctionComponent } from "react";
import { History } from "history";

import { ApolloContextWrapper } from "./apollo-context";

numeral.locale("en-gb");

const store = createStore(combineReducers({ form }));

attachIntercom();

const Entry: FunctionComponent<{ history: History }> = ({ history }) => (
  <Provider store={store}>
    <ApolloContextWrapper>
      <Router history={history}>
        <State>
          <Authorizer />
        </State>
      </Router>
    </ApolloContextWrapper>
  </Provider>
);

export default Entry;
