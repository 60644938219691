export const useOSKeyCommand = (): [string] => {
  if (
    navigator.platform.indexOf("Mac") === 0 ||
    navigator.platform === "iPhone"
  ) {
    return ["⌘"];
  }

  return ["^"];
};
