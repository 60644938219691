// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A83SlJ5vz1ctW1tXq6Rp{display:grid;gap:1rem;grid-template-columns:repeat(auto-fill, minmax(12rem, 1fr))}.lZPlkx6f5vcJmtW4tWy_{display:flex;align-items:stretch;align-content:stretch}@media screen and (min-width: 48em){.lZPlkx6f5vcJmtW4tWy_{overflow:hidden}}@media screen and (min-width: 65em){.lZPlkx6f5vcJmtW4tWy_{overflow:hidden}}\n", "",{"version":3,"sources":["webpack://./src/shared/components/resources/styles.scss"],"names":[],"mappings":"AAEA,sBACE,YAAa,CACb,QAAS,CACT,2DAA4D,CAC7D,sBAGC,YAAa,CACb,mBAAoB,CACpB,qBAAsB,CAEtB,oCALF,sBAMI,eAAgB,CAMnB,CAHC,oCATF,sBAUI,eAAgB,CAEnB","sourcesContent":["@import \"src/shared/styles/variables\";\n\n.container {\n  display: grid;\n  gap: 1rem;\n  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));\n}\n\n.card {\n  display: flex;\n  align-items: stretch;\n  align-content: stretch;\n\n  @media screen and (min-width: $sm-screen) {\n    overflow: hidden;\n  }\n\n  @media screen and (min-width: $container-max-width) {\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "A83SlJ5vz1ctW1tXq6Rp",
	"card": "lZPlkx6f5vcJmtW4tWy_"
};
export default ___CSS_LOADER_EXPORT___;
