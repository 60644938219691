import { Component } from "react";
import noop from "lodash/noop";

export const POLLING_INTERVAL = 60 * 1000; // 1 min
export const MAX_POLLING_INTERVAL = 3 * 60 * 60 * 1000; // 3 hrs

class AppVersionChecker extends Component {
  private initialHtml: string | null = null;

  componentDidMount(): void {
    // This function executes `checkAppVersion` every N seconds,
    // where N is an interval that grows exponentially every time the
    // function gets called, until it reaches `MAX_POLLING_INTERVAL`.
    this.exponentialBackoff(this.checkAppVersion, POLLING_INTERVAL);
  }

  private exponentialBackoff = (func: () => Promise<void>, delay: number) => {
    func().then(() => {
      window.setTimeout(
        () =>
          this.exponentialBackoff(
            func,
            Math.min(delay * 2, MAX_POLLING_INTERVAL),
          ),
        delay,
      );
    });
  };

  private checkAppVersion = () => {
    return fetch("/index.html")
      .then((r) => r.text())
      .then((html) => {
        if (!this.initialHtml) {
          this.initialHtml = html;
        } else if (html !== this.initialHtml) {
          window.location.reload();
        }
      })
      .catch(noop);
  };

  render(): JSX.Element {
    return <div>{this.props.children}</div>;
  }
}

export default AppVersionChecker;
