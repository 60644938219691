export const getResourceData = (
  resourceType: string,
): { id: string; pageTitle: string } => {
  const resourceTypeData: {
    [key: string]: {
      id: string;
      pageTitle: string;
    };
  } = {
    articles: {
      id: "ea6e5286-0125-41c4-aa36-267dc9a8c863",
      pageTitle: "Articles",
    },
    books: {
      id: "2541c860-d66d-4bc3-8a66-2a24b945e6c3",
      pageTitle: "Books",
    },
    coaches: {
      id: "e10a479e-5abd-49ce-8e9a-3ae349d709d1",
      pageTitle: "Coaches",
    },
    conferences: {
      id: "8d38d8c4-d89f-4a49-872d-77c787115b25",
      pageTitle: "Conferences",
    },
    courses: {
      id: "474ff0f7-5967-47d0-b115-733f816a448b",
      pageTitle: "Courses",
    },
    meetups: {
      id: "db01421c-a2d9-42a8-9b81-ddcaf96e6f1f",
      pageTitle: "Meetups",
    },
    onlineCourses: {
      id: "27e1fd5f-d8d9-423f-b376-2c39931a82df",
      pageTitle: "Online courses",
    },
    other: {
      id: "50b16298-772a-4267-9b58-5c61d6c98691",
      pageTitle: "Other",
    },
    podcasts: {
      id: "4b6fb07c-d68a-4226-83c1-0d57600afaeb",
      pageTitle: "Podcasts",
    },
    videos: { id: "05789e67-2ca8-4bbf-ba33-cf265d51282a", pageTitle: "Videos" },
  };

  return resourceTypeData[resourceType];
};
