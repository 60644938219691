import once from "lodash/once";

declare global {
  interface Window {
    Intercom(command: "shutdown"): void;
    Intercom(command: "hide"): void;
    Intercom(command: "show"): void;
    Intercom(command: "update", data: unknown): void;
    Intercom(command: "showNewMessage", data: unknown): void;
    Intercom(command: "boot", data: Record<string, unknown>): void;
    Intercom(
      command: "trackEvent",
      name: string,
      meta: Record<string, unknown>,
    ): void;

    // Add IE-specific interface to Window
    attachEvent(event: string, listener: EventListener): boolean;
  }
}

export const setupIntercom = once(function (data = {}) {
  if (process.env.INTERCOM_APP_ID) {
    window.Intercom("boot", {
      app_id: process.env.INTERCOM_APP_ID,
      ...data,
    });
  }
});

export function closeIntercom(): void {
  if (process.env.INTERCOM_APP_ID) {
    window.Intercom("shutdown");
  }
}

export function hideIntercom(): void {
  if (process.env.INTERCOM_APP_ID) {
    window.Intercom("hide");
  }
}

export function showIntercom(): void {
  if (process.env.INTERCOM_APP_ID) {
    window.Intercom("show");
  }
}

export function updateIntercom(data: unknown): void {
  if (process.env.INTERCOM_APP_ID) {
    window.Intercom("update", data);
  }
}

export function intercomMessage(message: unknown): void {
  if (process.env.INTERCOM_APP_ID) {
    window.Intercom("showNewMessage", message);
  }
}

export function trackIntercomEvent(name: string, meta = {}): void {
  window.Intercom("trackEvent", name, meta);
}

const addScript = () => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src = `https://widget.intercom.io/widget/${process.env.INTERCOM_APP_ID}`;
  const x = document.getElementsByTagName("script")[0];
  if (x && x.parentNode) {
    x.parentNode.insertBefore(script, x);
  }
};

interface Intercom {
  (): void;
  q: Array<unknown>;
  c(args: unknown): void;
}

export function attachIntercom(...params: Array<unknown>): void {
  if (process.env.INTERCOM_APP_ID) {
    const Intercom: Intercom = () => {
      Intercom.c(params);
    };

    Intercom.q = [];

    Intercom.c = (args) => {
      Intercom.q.push(args);
    };

    window.Intercom = Intercom;

    if (window.attachEvent) {
      window.attachEvent("onload", addScript);
    } else {
      window.addEventListener("load", addScript, false);
    }
  }
}
