// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wkwr79NQugr67AJJrylu{margin:0 0 30px 0;padding:0}.O5XfE6ylByRbbLS8xUqJ{padding:20px 0 0 0;display:flex;justify-content:flex-end}.USQyk3p8PcIVvWMgLp7d{max-width:50%;flex-grow:1;margin-right:15px}.USQyk3p8PcIVvWMgLp7d:last-child{margin-right:0}\n", "",{"version":3,"sources":["webpack://./src/shared/components/form/styles.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAkB,CAClB,SAAU,CACX,sBAGC,kBAAmB,CACnB,YAAa,CACb,wBAAyB,CAC1B,sBAGC,aAAc,CACd,WAAY,CACZ,iBAAkB,CAHpB,iCAMI,cAAe","sourcesContent":[".title {\n  margin: 0 0 30px 0;\n  padding: 0;\n}\n\n.buttons {\n  padding: 20px 0 0 0;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.button {\n  max-width: 50%;\n  flex-grow: 1;\n  margin-right: 15px;\n\n  &:last-child {\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "wkwr79NQugr67AJJrylu",
	"buttons": "O5XfE6ylByRbbLS8xUqJ",
	"button": "USQyk3p8PcIVvWMgLp7d"
};
export default ___CSS_LOADER_EXPORT___;
