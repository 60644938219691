import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FunctionComponent } from "react";
import { getJobFamilyLabel } from "src/shared/util/job-profile";
import styles from "./styles.scss";

const familyConfig: Record<string, string> = {
  "Customer success": styles.customerSuccess,
  Data: styles.data,
  Design: styles.design,
  Finance: styles.finance,
  Legal: styles.legal,
  Marketing: styles.marketing,
  Operations: styles.operations,
  "People/HR": styles.people,
  Product: styles.product,
  Research: styles.research,
  Sales: styles.sales,
  "Software engineering": styles.engineering,
};

interface JobFamilyBadgeProps {
  jobFamily: string;
  iconOnly?: boolean;
  large?: boolean;
}

const JobFamilyBadge: FunctionComponent<JobFamilyBadgeProps> = ({
  jobFamily,
  large,
  iconOnly,
}) => {
  if (jobFamily === "other") {
    return null;
  }

  const familyStyle = familyConfig[jobFamily];
  const family = getJobFamilyLabel(jobFamily);

  return (
    <div
      className={classNames(styles.container, familyStyle, {
        [styles.large]: large,
        [styles.iconOnly]: iconOnly,
      })}
    >
      <FontAwesomeIcon icon={faUsers} />
      {!iconOnly && <span>{family}</span>}
    </div>
  );
};

export default JobFamilyBadge;
