// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MMAnzexy9RHE2Q1phy8n{text-align:center;padding:80px 30px 100px;margin:0 auto;max-width:500px}.SEeq_FDLB2ANxo7Var1z{font-size:40px;margin-bottom:20px;animation:Z_jpyc_xZImIECjHb8GK 2s infinite}.oVVtpnwegKulOSjK1ZIR>p{font-size:20px;line-height:26px;margin-bottom:20px}@keyframes Z_jpyc_xZImIECjHb8GK{0%,50%,100%{transform:scale(1)}80%{transform:scale(1.2)}}\n", "",{"version":3,"sources":["webpack://./src/shared/components/empty/styles.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAkB,CAClB,uBAAwB,CACxB,aAAc,CACd,eAAgB,CACjB,sBAGC,cAAe,CACf,kBAAmB,CACnB,0CAA4B,CAC7B,wBAGC,cAAe,CACf,gBAAiB,CACjB,kBAAmB,CACpB,gCAGC,YAGE,kBAAmB,CAErB,IACE,oBAAqB,CAAA","sourcesContent":[".container {\n  text-align: center;\n  padding: 80px 30px 100px;\n  margin: 0 auto;\n  max-width: 500px;\n}\n\n.icon {\n  font-size: 40px;\n  margin-bottom: 20px;\n  animation: pulse 2s infinite;\n}\n\n.copy > p {\n  font-size: 20px;\n  line-height: 26px;\n  margin-bottom: 20px;\n}\n\n@keyframes pulse {\n  0%,\n  50%,\n  100% {\n    transform: scale(1);\n  }\n  80% {\n    transform: scale(1.2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MMAnzexy9RHE2Q1phy8n",
	"icon": "SEeq_FDLB2ANxo7Var1z",
	"pulse": "Z_jpyc_xZImIECjHb8GK",
	"copy": "oVVtpnwegKulOSjK1ZIR"
};
export default ___CSS_LOADER_EXPORT___;
