import { FunctionComponent, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useFeature } from "@growthbook/growthbook-react";

import { track } from "src/shared/util/segment";

import { AppContext } from "src/app-context";

import {
  hasAdHocRequests,
  isApprover,
  isOrgAdmin,
} from "src/shared/util/user-constraints";

import { getNavLinks } from "../NewHeader/get-nav-links";
import HamburgerNavigation from "./HamburgerNavigation";

const HamburgerNavigationWrapper: FunctionComponent = () => {
  const { pathname } = useLocation();

  const hasPotsEnabled = useFeature("pots").value;

  const { currentUser, orgPageUrl, setShowNavigation, showNavigation } =
    useContext(AppContext);

  if (!currentUser) {
    return null;
  }

  const {
    tasks: { approvals },
  } = currentUser;

  const hasPendingApprovals = approvals.length > 0;

  const userIsApprover = isApprover(currentUser);
  const userIsOrgAdmin = isOrgAdmin(currentUser);

  const trackClick = (title: string) => {
    setShowNavigation("default");
    track("Clicked Nav", {
      title,
    });
  };

  const links = getNavLinks({
    hasPendingApprovals,
    orgPageUrl,
    pathname,
    userIsApprover,
    userIsOrgAdmin,
    hasPotsEnabled,
    trackClick,
    isForMobile: true,
  });

  return (
    <>
      {showNavigation === "hamburger" && (
        <HamburgerNavigation
          links={links}
          hasAdHocRequests={hasAdHocRequests(currentUser)}
          setShowNavigation={setShowNavigation}
          trackClick={trackClick}
        />
      )}
    </>
  );
};

export default HamburgerNavigationWrapper;
