import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faMapMarkerAlt,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import uniqBy from "lodash/uniqBy";

import PropTypes from "prop-types";

import { formatText } from "../../util/text-display";
import { track } from "../../util/segment";
import styles from "./styles.scss";

export const determineInternalEvents = (products) => {
  if (!products) {
    return {};
  }

  const internalEvents = products.filter(
    ({ type }) => type === "internalEvent",
  );

  if (!internalEvents.length) {
    return {};
  }

  const firstEvent = internalEvents[0];
  const hasCity = !!(firstEvent.location && firstEvent.location.city);

  return {
    hasMultipleDates: internalEvents.length >= 2,
    startDate: firstEvent.startDate,
    timezone: firstEvent.timezone,
    hasCity,
    hasMultipleCities:
      uniqBy(
        internalEvents.filter((event) => event.location),
        "location.city",
      ).length >= 2,
    city: hasCity && firstEvent.location.city,
  };
};

const InternalEventCard = ({ resource, trackContext, trackPosition }) => {
  const { id, products, title } = resource;
  const {
    city,
    hasCity,
    hasMultipleCities,
    hasMultipleDates,
    startDate,
    timezone,
  } = determineInternalEvents(products);

  const trackClick = () =>
    track("Product Clicked", {
      product_id: resource.id,
      sku: resource.id,
      name: resource.title,
      context: trackContext,
      position: trackPosition,
      category: resource.type.name,
      isInternalEvent: true,
      index: `${process.env.STAGE}_resources`,
      objectID: resource.id,
      eventType: "click",
    });

  return (
    <Link className={styles.card} to={`/resources/${id}/`} onClick={trackClick}>
      <div className={styles.headerContainer}>
        <h4 className={styles.header}>{formatText(title, 150)}</h4>
      </div>

      <div className={styles.details}>
        {startDate && (
          <div className={styles.detail}>
            <div className={styles.icon}>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
            <span>{moment.tz(startDate, timezone).format("D MMM YY")}</span>
            {hasMultipleDates && (
              <span className={styles.hasMultiple}>&#43; more</span>
            )}
          </div>
        )}

        <div className={styles.detail}>
          {hasCity && (
            <>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </div>
              <span>{city}</span>
              {hasMultipleCities && (
                <span className={styles.hasMultiple}>&#43; more</span>
              )}
            </>
          )}
        </div>
      </div>
    </Link>
  );
};

InternalEventCard.propTypes = {
  large: PropTypes.bool,
  resource: PropTypes.object.isRequired,
  trackContext: PropTypes.string,
  trackPosition: PropTypes.number,
};

export default InternalEventCard;
