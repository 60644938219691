import { FunctionComponent, useContext } from "react";
import { useMutation } from "@apollo/client";

import { AppContext } from "src/app-context";

import ProfileNavigationWidget from "./ProfileNavigationWidget";

import { seedPotsDemoData as TSeedPotsDemoData } from "./__generated__/seedPotsDemoData";

import styles from "./styles.scss";

import seedPotsDemoDataMutation from "./seed-pots-demo-data.ts.graphql";
import { Link } from "react-router-dom";
import { useFeature } from "@growthbook/growthbook-react";
import {
  isInDemoOrganisation,
  isOrgAdmin,
} from "src/shared/util/user-constraints";

const ProfileNavigationWidgetWrapper: FunctionComponent = () => {
  const { setAlert, orgPageUrl, currentUser } = useContext(AppContext);

  const onError = () => {
    setAlert({
      type: "error",
      message: "Ouch, something went wrong 🤦‍♀️!",
      errorType: "error",
    });
  };

  const hasPotsEnabled = useFeature("pots").value;

  const shouldShowResetDemoDataOption =
    hasPotsEnabled &&
    isOrgAdmin(currentUser) &&
    isInDemoOrganisation(currentUser);

  const [seedPotsDemoData] = useMutation<TSeedPotsDemoData, null>(
    seedPotsDemoDataMutation,
    {
      onCompleted: ({ seedPotsDemoData }) => {
        const message = `🎉 Pots demo data has been reset successfully! 🎉`;
        if (seedPotsDemoData.success) {
          setAlert({
            type: "success",
            message: (
              <>
                <p>{message}</p>
                <Link
                  to={`/${orgPageUrl}/account/`}
                  className={styles.alertLink}
                >
                  Go to account page.
                </Link>
              </>
            ),
          });
        } else {
          onError();
        }
      },
      onError,
    },
  );

  const handleResetDemoData = async () => {
    await seedPotsDemoData();
  };

  return (
    <ProfileNavigationWidget
      onResetDemoData={handleResetDemoData}
      shouldShowResetDemoDataOption={shouldShowResetDemoDataOption}
    />
  );
};

export default ProfileNavigationWidgetWrapper;
