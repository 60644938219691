import { FunctionComponent } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAppContext } from "../../../app-context";

export const AuthenticatedRoute: FunctionComponent<RouteProps> = ({
  children,
  ...props
}) => {
  const { currentUser } = useAppContext();

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  return <Route {...props}>{children}</Route>;
};
