import {
  createContext,
  useCallback,
  useRef,
  useState,
  useContext,
} from "react";
import PropTypes from "prop-types";

import Alert from "src/shared/components/alert";
import { SkipToMainContent } from "./shared/components/SkipToMainContent/SkipToMainContent";

import { logError } from "./shared/util/error-logging";

import App from "./app";
import { useLocation } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const AppContext = createContext();

const AppContextWrapper = ({ user }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showFooter, setShowFooter] = useState(true);
  const [showNavigation, setShowNavigation] = useState("default");
  const isFixedAdminRoutesActive = useFeatureIsOn("fixed_admin_routes");

  const { pathname } = useLocation();

  const timerHandle = useRef();

  const setAlert = useCallback(({ type, errorType, error, message } = {}) => {
    // type refers to the type of alert to show in the UI (success or error)
    // errorType refers to the type of error and determines whether it is logged to sentry (error or warning)
    // error refers to the error object - if it is a user error, it will not be logged to sentry (see below)

    setShowAlert(true);
    setAlertType(type);
    setAlertMessage(message);

    clearTimeout(timerHandle.current);
    timerHandle.current = setTimeout(() => setShowAlert(false), 6000);

    const isUserError = error?.graphQLErrors?.some(
      (error) => error.extensions.code === "USER_ERROR",
    );

    if (errorType === "error" && !isUserError) {
      logError(new Error(message));
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        setAlert,
        showFooter,
        setShowFooter,
        showNavigation,
        setShowNavigation,
        currentUser: user,
        orgPageUrl:
          pathname.startsWith("/admin/") || isFixedAdminRoutesActive
            ? "admin"
            : user?.orgPageUrl,
      }}
    >
      <SkipToMainContent />
      <Alert
        message={alertMessage}
        show={showAlert}
        onClose={() => setShowAlert(false)}
        type={alertType}
      />
      <App />
    </AppContext.Provider>
  );
};

AppContextWrapper.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    activeIndividualBudget: PropTypes.shape({
      id: PropTypes.string,
    }),
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        state: PropTypes.string,
        diallingCode: PropTypes.string,
        postcode: PropTypes.string,
        street: PropTypes.string,
        telephone: PropTypes.string,
      }),
    ),
    admin: PropTypes.bool,
    approvers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    approversOf: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    country: PropTypes.string,
    createdAt: PropTypes.string,
    currency: PropTypes.string,
    email: PropTypes.string,
    exchangeRates: PropTypes.shape({
      [PropTypes.string]: PropTypes.number,
    }),
    featureToggles: PropTypes.arrayOf(PropTypes.string),
    firstName: PropTypes.string,
    focuses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        subject: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string,
        }),
      }),
    ),
    geographicLocation: PropTypes.string,
    goals: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        status: PropTypes.string,
      }),
    ),
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        archived: PropTypes.bool,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    hasCookieConsent: PropTypes.bool,
    hasPassword: PropTypes.bool,
    image: PropTypes.string,
    intercomHmac: PropTypes.string,
    isImpersonated: PropTypes.bool,
    lastName: PropTypes.string,
    locale: PropTypes.string,
    onboarded: PropTypes.bool,
    orgPageUrl: PropTypes.string,
    organisation: PropTypes.shape({
      adhocRequests: PropTypes.bool,
      analyticsDashboard: PropTypes.bool,
      budgetPeriods: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
      createdAt: PropTypes.string,
      id: PropTypes.string,
      internalEvents: PropTypes.bool,
      internalLearning: PropTypes.bool,
      logo: PropTypes.string,
      name: PropTypes.string,
      recentActivity: PropTypes.bool,
      testClient: PropTypes.bool,
    }),
    organisationAdmin: PropTypes.bool,
  }),

  children: PropTypes.element,
};

const useAppContext = () => useContext(AppContext);

export { AppContext, AppContextWrapper, useAppContext };
