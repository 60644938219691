import { EditoralContent, EntityType } from "../components/editorial-banner";

import dataImage1 from "src/shared/assets/editorial/data1.png";
import dataImage2 from "src/shared/assets/editorial/data2.png";
import dataImage3 from "src/shared/assets/editorial/data3.png";

import peopleImage1 from "src/shared/assets/editorial/people1.png";
import peopleImage2 from "src/shared/assets/editorial/people2.png";
import peopleImage3 from "src/shared/assets/editorial/people3.png";

import softwareEngineeringImage from "src/shared/assets/editorial/softwareEngineering.png";
import salesImage from "src/shared/assets/editorial/sales.png";
import productImage from "src/shared/assets/editorial/product.png";

import learnerblyLogo from "src/shared/assets/editorial/learnerbly.png";
import dataNick from "src/shared/assets/editorial/datanick.png";

interface JobFamilyConfig {
  isActive?: boolean;
  label: string;
  route?: string;
  sublabel?: string;
  value?: string;
  trendTopicId?: string;
  editorial?: EditoralContent;
  playlists?: Array<string>;
  suppliers?: Array<string>;
}

interface JobLevelConfig {
  label: string;
  sublabel?: string;
  value?: string;
}

export const jobFamilies: Array<JobFamilyConfig> = [
  {
    label: "Customer success",
  },
  {
    label: "Data",
    route: "data",
    isActive: true,
    trendTopicId: "2e668564-bb43-41e7-a962-3d6ce401567c", // "Data" topic
    editorial: {
      title: "Intro to data engineering and the modern data stack",
      description:
        "5 resources to get you started with Data engineering picked by Learnerbly's Data Lead.",
      images: [dataImage1, dataImage2, dataImage3],
      creator: {
        name: "Nick Cameron, Data Lead @Learnerbly",
        image: dataNick,
      },
      type: EntityType.Playlist,
      entityId: "5691fac5-284c-44e6-a866-7852ec97c5d6",
      linkText: "Take a look",
    },
    playlists: [
      "e179f35c-9326-4113-9f8e-0acf45f37fac",
      "7b9d7567-a475-403a-a9b1-cd54e946f0f5",
      "5691fac5-284c-44e6-a866-7852ec97c5d6",
    ],
    suppliers: [
      "e5ca5b20-a463-4148-9f47-b876f7789937",
      "e27c42b2-d176-4a6a-a1bf-a4ea418bc26e",
      "1a88989e-92bc-46c3-8c46-0119683dd7dd",
      "f9238200-9e7c-4e99-9e3d-dd19fbceea81",
    ],
  },
  {
    label: "Design",
  },
  {
    label: "Finance",
  },
  {
    label: "Legal",
  },
  {
    label: "Marketing",
  },
  {
    label: "Operations",
  },
  {
    label: "People/HR",
    route: "people",
    isActive: true,
    trendTopicId: "e7cb4c87-cab2-4e32-88b1-98e79b4eb4f0", // "HR" topic
    editorial: {
      title: "Bridge your PX strategy and commercial goals 📈",
      description:
        "Take your commercial acumen from zero to hero and keep business goals at the front of your mind on every approach, decision, and action you take.",
      images: [peopleImage1, peopleImage2, peopleImage3],
      creator: {
        name: "The Learnerbly Curators",
        image: learnerblyLogo,
      },
      type: EntityType.Playlist,
      entityId: "3c410cdf-2513-460f-8bf0-5d5006586999",
      linkText: "Take a look",
    },
    playlists: [
      "3c410cdf-2513-460f-8bf0-5d5006586999",
      "302a5965-80e6-4bb2-ad00-e1995e4230d8",
      "4137cb34-414b-45d6-a847-ed2cec58b2dc",
    ],
    suppliers: [
      "34192612-8a10-4b16-b9f0-47882205d09f",
      "912cffa8-8687-452b-b540-ff68a2a96127",
      "4979488a-a28c-42c0-bde1-ae9315581745",
      "ddced154-6d53-47de-9913-c8dd838f8b19",
    ],
  },
  {
    label: "Product",
    route: "product",
    isActive: true,
    trendTopicId: "73b6adeb-a761-4926-bc7c-238ab69a8737", // "Product" topic
    editorial: {
      title: "Product strategy mentoring",
      description:
        "Ready to move your product career ahead this 2023? Find your match among 2,000+ top industry mentors and reach your goals faster ✨",
      images: [productImage],
      type: EntityType.Resource,
      entityId: "e4b17e0e-a7e1-4135-9191-39307af7b633",
      linkText: "Take a look",
    },
    playlists: [
      "cb7340ee-8a1a-4f94-9817-f6bda8e28cb7",
      "6b75ee00-4133-41f8-b7c4-ae43a0818004",
      "a9adbb24-a195-45d0-ba9d-c953c1f1d326",
    ],
    suppliers: [
      "c6a5d4c0-2bda-41a9-bbca-8cc30617f87b",
      "f4080e21-6f85-431c-83ff-64db2027c852",
      "28c38b76-fcd9-44fd-b5dd-20628c984bce",
      "36d54d8c-9a7c-414a-aefc-b4005ec18901",
    ],
  },
  {
    label: "Research",
  },
  {
    label: "Sales",
    route: "sales",
    isActive: true,
    trendTopicId: "9531509e-6af6-419a-ac59-3accf2a091da", // "Sales" topic
    editorial: {
      title: "Account Executive frameworks: Complete series",
      description:
        "Discover blueprints to navigate the 4 core aspects of any deal cycle. You’ll get real-world examples, as well as common pitfalls and how to avoid them.",
      images: [salesImage],
      type: EntityType.Resource,
      entityId: "187d42df-e438-4d57-b221-add1dda7f757",
      linkText: "Take a look",
    },
    playlists: [
      "d2248aef-c113-4a43-ac16-39cddc6dc17a",
      "d4dddea6-031d-4a2c-947c-6ab4e840d891",
      "a905631d-6f30-4179-8783-aed6ed12cd2c",
    ],
    suppliers: [
      "d1f9fa74-c0cd-453f-ab4f-fcefc4000907",
      "d9158f75-0ced-471c-b79f-f6ccec2f5f91",
      "1034b551-dbc2-42ce-9b39-4acca62145a0",
      "7aa307db-9bc1-481d-b232-cb12f1f2b8bd",
    ],
  },
  {
    label: "Software engineering",
    value: "Software engineering",
    route: "software-engineering",
    isActive: true,
    trendTopicId: "cb65ba2e-9867-415f-a478-6b3f5a1d068d", // "Software Engineering" topic
    editorial: {
      title: "Codecademy subscription",
      description:
        "Join over 50 million people building your coding skills and advancing your career with Codecademy. Get access to hundreds of lessons and amazing projects across 14 programming languages.",
      images: [softwareEngineeringImage],
      type: EntityType.Resource,
      entityId: "2eb7ae26-7a86-4c06-8acf-423654b27971",
      linkText: "Take a look",
    },
    playlists: [
      "303c7e2c-8f4f-41f1-98fc-9a5989239e93",
      "f8f578c4-7387-4c1d-b6fc-68058d2075b1",
      "5c2e6721-ed7b-4818-ab51-8565c6a32e0b",
    ],
    suppliers: [
      "d5a7af60-d696-4b74-8edf-2a4022a0abd7",
      "e27c42b2-d176-4a6a-a1bf-a4ea418bc26e",
      "e64a7977-c9f4-49c3-a09e-c81887c8ee62",
      "f9238200-9e7c-4e99-9e3d-dd19fbceea81",
    ],
  },
];

export const jobLevels: Array<JobLevelConfig> = [
  {
    label: "Junior level",
    value: "Junior",
    sublabel:
      "You're new to your role, or you have a little bit of experience.",
  },
  {
    label: "Mid level",
    value: "Mid",
    sublabel:
      "You've hit your stride and have some level of experience in your role.",
  },
  {
    label: "Senior level",
    value: "Senior",
    sublabel:
      "You're very seasoned in your role. This level includes leads, and heads of departments.",
  },
];

export const getJobFamilyConfig = (
  jobFamily: string,
): JobFamilyConfig | undefined =>
  jobFamilies.find(
    (jobFamilyConfig: JobFamilyConfig) =>
      jobFamilyConfig.value === jobFamily ||
      jobFamilyConfig.label === jobFamily,
  );

export const getJobFamilyRoute = (jobFamily: string): string | undefined => {
  const jobFamilyConfig = getJobFamilyConfig(jobFamily);

  return jobFamilyConfig?.route ? `/role/${jobFamilyConfig.route}` : undefined;
};

export const getJobFamilyLabel = (jobFamily: string): string | undefined => {
  const jobFamilyConfig = getJobFamilyConfig(jobFamily);

  return jobFamilyConfig?.label ?? undefined;
};

export const getJobFamilyTrendingTopicId = (
  jobFamily: string,
): string | undefined => {
  const jobFamilyConfig = getJobFamilyConfig(jobFamily);

  return jobFamilyConfig?.trendTopicId ?? undefined;
};

export const getJobFamilyPlaylistIds = (jobFamily: string): Array<string> => {
  const jobFamilyConfig = getJobFamilyConfig(jobFamily);

  return jobFamilyConfig?.playlists ?? [];
};

export const getJobFamilySupplierIds = (jobFamily: string): Array<string> => {
  const jobFamilyConfig = getJobFamilyConfig(jobFamily);

  return jobFamilyConfig?.suppliers ?? [];
};

export const getJobFamilyEditorialContent = (
  jobFamily: string,
): EditoralContent | undefined => {
  const jobFamilyConfig = getJobFamilyConfig(jobFamily);

  return jobFamilyConfig?.editorial ?? undefined;
};

export const getActiveJobFamilies = (): Array<string> => {
  const activeJobFamilyConfigs = jobFamilies.filter(
    (jobFamily) => jobFamily.isActive,
  );

  const activeJobFamilies = activeJobFamilyConfigs.map(
    (jobFamily) => jobFamily.value || jobFamily.label,
  );

  return activeJobFamilies;
};

export const isActiveJobFamily = (jobFamily: string): boolean => {
  const jobFamilyConfig = getJobFamilyConfig(jobFamily);

  return jobFamilyConfig?.isActive ?? false;
};
