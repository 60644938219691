import { createContext, useContext } from "react";
import { useOpenSearchCommand } from "../hooks/useOpenSearchCommand";
import { useInputSearchCommand } from "../hooks/useInputSearchCommand";
import { useAISearchCommand } from "../hooks/useAISearchCommand";
import { useProviderSearchCommand } from "../hooks/useProviderSearchCommand";
import { useTrackSearchCommand } from "../hooks/useTrackSearchCommand";
import { AppContext } from "src/app-context";

const SearchCommandContext = createContext<{
  open: ReturnType<typeof useOpenSearchCommand>[0];
  setOpen: ReturnType<typeof useOpenSearchCommand>[1];
  search: ReturnType<typeof useInputSearchCommand>[0];
  setSearch: ReturnType<typeof useInputSearchCommand>[1];
  ai: ReturnType<typeof useAISearchCommand>[0];
  setAi: ReturnType<typeof useAISearchCommand>[1];
  provider: ReturnType<typeof useProviderSearchCommand>[0] | null;
  providerLoading: ReturnType<typeof useProviderSearchCommand>[1];
}>({
  open: false,
  setOpen: () => ({}),
  search: "",
  setSearch: () => ({}),
  ai: false,
  setAi: () => ({}),
  provider: null,
  providerLoading: false,
});

interface SearchCommandContextProviderProps {
  children: React.ReactNode;
}

const SearchCommandContextProvider = (
  props: SearchCommandContextProviderProps,
): JSX.Element => {
  const { currentUser } = useContext(AppContext);

  const [open, setOpen] = useOpenSearchCommand();
  const [search, setSearch] = useInputSearchCommand();
  const [ai, setAi] = useAISearchCommand();
  const [provider, providerLoading] = useProviderSearchCommand(
    { search, ai },
    { currentUser },
  );
  useTrackSearchCommand({ search, ai });

  const state = {
    open,
    setOpen,
    search,
    setSearch,
    ai,
    setAi,
    provider,
    providerLoading,
  };

  return (
    <SearchCommandContext.Provider value={state}>
      {props.children}
    </SearchCommandContext.Provider>
  );
};

export { SearchCommandContext, SearchCommandContextProvider };
