import {
  GrowthBook as GrowthBookConstructor,
  GrowthBookProvider,
} from "@growthbook/growthbook-react";
import { FunctionComponent, useEffect } from "react";

import { track } from "src/shared/util/segment";

import { whoami_user as TUser } from "../authorizer/__generated__/whoami";

export const growthBookContext = new GrowthBookConstructor({
  apiHost: "https://cdn.growthbook.io",
  enableDevMode: process.env.STAGE === "development",
  clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
  onFeatureUsage: (featureKey, result) => {
    if (result.on) {
      track("Feature Active", {
        ruleId: result.ruleId,
        source: result.source,
        value: result.value,
        featureKey,
      });
    }
  },
  trackingCallback: (experiment, result) => {
    track("Experiment Viewed", {
      experimentKey: experiment.key,
      variantId: result.variationId,
    });
  },
});

export const GrowthBook: FunctionComponent<{ currentUser: TUser | null }> = ({
  children,
  currentUser,
}) => {
  useEffect(() => {
    growthBookContext.loadFeatures({ autoRefresh: true });
  }, []);

  useEffect(() => {
    growthBookContext.setAttributes({
      userId: currentUser?.id,
      organisationId: currentUser?.organisation.id,
      organisationName: currentUser?.organisation.name,
      hasPots: currentUser?.organisation.hasPots,
      contentRegion: currentUser?.country,
      currencyCode: currentUser?.currency,
    });
  }, [currentUser]);

  return (
    <GrowthBookProvider growthbook={growthBookContext}>
      {children}
    </GrowthBookProvider>
  );
};
