import intersectionBy from "lodash/intersectionBy";
import moment from "moment";

import { availableFocuses } from "../shared/util/pdp-focuses";

export const mapFocuses = (focuses, selected) => {
  return focuses.map(({ id, name }) => ({
    name,
    value: id,
    isSelected: selected.includes(id),
  }));
};

export const getFocusName = (id) => {
  return availableFocuses.find((focus) => focus.id === id).name;
};

export const transformQueryToState = (
  { goals, id, startedPDP, pdpFocuses, firstName, lastName },
  currentUserId,
) => ({
  startedPDP,
  openGoals: goals ? goals.filter(({ status }) => status === "OPEN") : [],
  doneGoals: goals ? goals.filter(({ status }) => status === "DONE") : [],
  userFocuses: pdpFocuses ? pdpFocuses.map(({ id }) => id) : [],
  userId: id,
  firstName,
  lastName,
  isOwnerOfGoals: currentUserId === id,
});

export const transformStateToMutation = ({ tagged, timeframe, goal }) => {
  return {
    focuses: tagged,
    description: goal.value,
    dueDate: moment().add(timeframe, "weeks").toISOString(),
  };
};

export const mapSelectedFocusesToItems = (selected, tagged) => {
  return mapFocuses(
    availableFocuses,
    intersectionBy(
      selected.map((id) => ({ id })),
      "id",
    ),
    tagged,
  );
};
