import classnames from "classnames";
import { FunctionComponent } from "react";

import styles from "./styles.scss";

interface Props {
  className?: string;
  emoji: string;
}

const Empty: FunctionComponent<Props> = ({ emoji, children, className }) => (
  <div className={classnames(styles.container, className)}>
    <div className={styles.icon}>{emoji}</div>
    <div className={styles.copy}>{children}</div>
  </div>
);

export default Empty;
