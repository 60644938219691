export const availableFocuses = [
  {
    id: "610d507e-47c5-4166-838e-4f4694648c75",
    name: "Communication",
  },
  {
    id: "7025ccb4-1ade-40aa-bd5b-7f5f3c0a6046",
    name: "Confidence",
  },
  {
    id: "eff98334-01ec-4824-94a0-66fc615271c1",
    name: "Creativity",
  },
  {
    id: "a48d901f-d10b-4d73-8225-ccec888ea6d0",
    name: "Leadership",
  },
  {
    id: "86d154da-45fd-4744-96f6-6c9bfbfda7f9",
    name: "Problem Solving",
  },
  {
    id: "cfba3632-38c1-4962-9ef6-0e6041fc0a61",
    name: "Role specific",
  },
  {
    id: "9660852b-c2c4-42d1-b9c4-2241b688f3d8",
    name: "Productivity",
  },
  {
    id: "2525cca5-93b3-4e40-91ba-4dfc8c67b876",
    name: "Wellbeing",
  },
];
