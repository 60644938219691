import { FunctionComponent, useContext } from "react";
import { Redirect } from "react-router-dom";
import qs from "qs";
import { useLocation } from "react-router-dom";
import doesIdentityProviderExist from "./doesIdentityProviderExist.graphql";
import { useQuery } from "@apollo/client";
import MainContainer from "../shared/components/main-container";
import Loading from "src/shared/components/loading";
import { federatedSignIn } from "src/shared/util/auth";
import { AppContext } from "src/app-context";
import {
  doesIdentityProviderExistVariables as TDoesIdentityProviderExistVariables,
  doesIdentityProviderExist as TDoesIdentityProviderExist,
} from "./__generated__/doesIdentityProviderExist";
import ErrorComponent from "src/shared/components/error";

const AuthInit: FunctionComponent = () => {
  const { search } = useLocation();
  const {
    clientId,
    customProvider,
  }: {
    clientId?: string;
    customProvider?: string;
  } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const { data, error } = useQuery<
    TDoesIdentityProviderExist,
    TDoesIdentityProviderExistVariables
  >(doesIdentityProviderExist, {
    fetchPolicy: "network-only",
    skip: !customProvider,
    variables: { customProvider: customProvider as string },
  });

  const { currentUser } = useContext(AppContext);

  if (error) {
    return <ErrorComponent />;
  }

  if (currentUser) {
    return <Redirect to="/" />;
  } else {
    if (customProvider) {
      if (data) {
        const {
          doesIdentityProviderExist: { doesExist },
        } = data;

        if (doesExist) {
          federatedSignIn(customProvider);
        } else {
          throw new Error("Identity Provider does not exist");
        }
      }
    } else if (clientId === "76k93musimaga8sou70qmojr5d") {
      // temporary as long as ClearScore is not using teh Okta app
      federatedSignIn("ClearScoreOkta");
    } else {
      throw new Error("Auth init failed");
    }
  }

  return (
    <MainContainer>
      <Loading />
    </MainContainer>
  );
};

export default AuthInit;
