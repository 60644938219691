import { useContext } from "react";

import { setupIntercom } from "../../util/intercom";
import { AppContext } from "src/app-context";

const Intercom = () => {
  const { currentUser } = useContext(AppContext);

  const data = {
    ...(currentUser && {
      user_id: currentUser.id,
      email: currentUser.email,
      user_hash: currentUser.intercomHmac,
      isOrganisationAdmin: currentUser.organisationAdmin,
      company: {
        name: currentUser.organisation.name,
        id: currentUser.organisation.id,
        created_at: currentUser.organisation.createdAt,
      },
    }),
  };

  setupIntercom(data);

  return null;
};

export default Intercom;
