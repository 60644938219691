import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, Link, useLocation } from "react-router-dom";
import { AppContext } from "src/app-context";
import { faFlagCheckered, faBars } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import classNames from "classnames";
import moment from "moment-timezone";
import { useFeature, useFeatureIsOn } from "@growthbook/growthbook-react";

import {
  isApprover,
  hasAdHocRequests,
  hasStartedPDP,
  isOrgAdmin,
} from "src/shared/util/user-constraints";
import PropTypes from "prop-types";

import { formatCurrencyValue } from "../../util/text-display";

import { track } from "src/shared/util/segment";

import useMediaQuery from "src/shared/hooks/use-media-query";

import Logo from "../logo";
import OffPlatformResourceButton from "src/shared/components/off-platform-resource-button";
import ProfileNavigation from "../profile-navigation-widget";
import RequestAnythingButton from "src/shared/components/request-anything-button";
import SearchForm from "../search-form";

import { Text } from "@learnerbly/web-components";

import styles from "./styles.scss";

const PageLink = ({ to, children, exact, isActive }) => (
  <>
    <NavLink
      activeClassName={styles.active}
      className={styles.link}
      to={to}
      exact={exact}
      isActive={isActive}
    >
      <div className={styles.linktext}>{children}</div>
    </NavLink>
  </>
);

PageLink.defaultProps = {
  exact: false,
};

PageLink.propTypes = {
  to: PropTypes.string,
  exact: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isActive: PropTypes.func,
};

export const Header = () => {
  const isDesktop = useMediaQuery("(min-width: 1184px)");
  const { currentUser, orgPageUrl, setShowNavigation, showNavigation } =
    useContext(AppContext);
  const fixedAdminRoutesActive = useFeatureIsOn("fixed_admin_routes");

  const { pathname } = useLocation();

  const hasPotsEnabled = useFeature("pots").value;

  if (!currentUser) {
    return null;
  }

  const {
    activeIndividualBudget,
    organisation: { logo },
    tasks: { approvals },
  } = currentUser;
  const remainingBudget =
    activeIndividualBudget && activeIndividualBudget.remaining
      ? activeIndividualBudget.remaining
      : 0;

  const formattedBudgetWithDecimals =
    activeIndividualBudget &&
    formatCurrencyValue(
      Math.max(0, remainingBudget),
      activeIndividualBudget.currency,
      currentUser.locale,
    );

  const lastDayOfBudget =
    activeIndividualBudget &&
    moment(activeIndividualBudget.endDate).format("D MMMM YYYY");

  const isBudgetEmpty = remainingBudget <= 0;

  const openGoals = currentUser.goals
    ? currentUser.goals.filter((goal) => goal.status === "OPEN")
    : [];

  const hasPendingApprovals = approvals.length > 0;

  const trackClick = (title) =>
    track("Clicked Nav", {
      title,
    });

  const collapseLinksEarly = isApprover(currentUser) || isOrgAdmin(currentUser);

  const adminPath = fixedAdminRoutesActive ? "admin" : orgPageUrl;

  return (
    <header className={styles.header}>
      <nav
        className={classNames(
          styles.navigation,
          collapseLinksEarly && styles.collapseLinksEarly,
        )}
      >
        <div data-cy="logo" className={styles.left}>
          <FontAwesomeIcon
            onClick={() =>
              setShowNavigation(
                showNavigation === "hamburger" ? "default" : "hamburger",
              )
            }
            className={styles.menu}
            icon={faBars}
          />
        </div>
        <div className={styles.middle}>
          <div className={styles.logo}>
            <Link
              to="/"
              aria-hidden={isDesktop}
              tabIndex={isDesktop ? -1 : 0}
              aria-label="Learnerbly home"
            >
              {logo ? <img src={logo} alt="logo" /> : <Logo />}
            </Link>
          </div>
          <div className={styles.links}>
            <PageLink to="/" exact onClick={() => trackClick("Home")}>
              Home
            </PageLink>
            <PageLink to={"/explore/"} onClick={() => trackClick("Explore")}>
              Explore
            </PageLink>
            {isApprover(currentUser) && (
              <PageLink
                to="/approvals/"
                isActive={() =>
                  pathname === "/approvals/" ||
                  pathname === "/approvals/history/"
                }
                onClick={() => trackClick("Team")}
              >
                <div className={styles.notificationWrapper}>
                  Team
                  {hasPendingApprovals && (
                    <div className={styles.pendingBadge} />
                  )}
                </div>
              </PageLink>
            )}
            {isOrgAdmin(currentUser) && (
              <PageLink
                exact
                to={`/${adminPath}/`}
                isActive={() =>
                  pathname === `/${adminPath}/` ||
                  pathname === `/${adminPath}/account/` ||
                  pathname === `/${adminPath}/content/` ||
                  pathname === `/${adminPath}/events/` ||
                  pathname === `/${adminPath}/people/` ||
                  pathname === `/${adminPath}/groups/` ||
                  pathname === `/${adminPath}/requests/` ||
                  pathname === `/${adminPath}/settings/`
                }
                onClick={() => trackClick("Admin")}
              >
                Admin
              </PageLink>
            )}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.search}>
            <SearchForm />
          </div>

          {hasAdHocRequests(currentUser) ? (
            <div className={styles.requestButton}>
              <RequestAnythingButton />
            </div>
          ) : (
            <div className={styles.requestButton}>
              <OffPlatformResourceButton trackClick={trackClick} />
            </div>
          )}

          {hasStartedPDP(currentUser) && (
            <Link to="/goals/" className={styles.goals}>
              <FontAwesomeIcon
                icon={faFlagCheckered}
                className={styles.goalIcon}
              />
              <Text textStyle="small" textVariant="bold">
                <span className={styles.goalsLabel}>
                  {`${openGoals.length} goal${
                    openGoals.length !== 1 ? "s" : ""
                  }`}
                </span>
              </Text>
            </Link>
          )}
          {!hasPotsEnabled && (
            <div className={styles.budgetContainer}>
              {activeIndividualBudget && (
                <div className={styles.budget}>
                  <Text textStyle="small" textVariant="bold">
                    {formattedBudgetWithDecimals}
                  </Text>
                </div>
              )}
              <div className={styles.budgetOverlay}>
                <Text textStyle="small">
                  {isBudgetEmpty
                    ? "You have no budget left to spend 😥"
                    : `You have ${formattedBudgetWithDecimals} left to spend until ${lastDayOfBudget} 😄`}
                </Text>
              </div>
            </div>
          )}
          <ProfileNavigation />
        </div>
      </nav>
    </header>
  );
};

export default Header;
