import {
  ApolloClient,
  ApolloError,
  NormalizedCacheObject,
} from "@apollo/client";
import { CommandResource, CurrentUser } from "../types";
import { ResourceProvider } from "./ResourceProvider";
import { INTELLIGENT_SEARCH_RESOURCES } from "./query/intelligentSearchResources.graphql";

const ABORT_ERROR = new Error("Request cancelled");

export class AIResourceProvider implements ResourceProvider {
  private pendingRequest: AbortController | null = null;

  constructor(
    private client: ApolloClient<NormalizedCacheObject>,
    private currentUser: CurrentUser,
  ) {}

  async fetch(query: string): Promise<Array<CommandResource>> {
    if (this.pendingRequest) {
      this.pendingRequest.abort(ABORT_ERROR);
    }

    if (!query) {
      return [];
    }

    this.pendingRequest = new AbortController();

    const { data } = await this.client.query({
      query: INTELLIGENT_SEARCH_RESOURCES,
      variables: { query, currency: this.currentUser.currency },
      fetchPolicy: "network-only",
      context: {
        fetchOptions: {
          signal: this.pendingRequest.signal,
        },
      },
    });

    return data.intelligentSearchResources;
  }

  async query(query: string): Promise<Array<CommandResource>> {
    return new Promise((resolve, reject) => {
      this.fetch(query)
        .then(resolve)
        .catch((error) => {
          const err = error as ApolloError;
          if (err.networkError !== ABORT_ERROR) {
            reject(err);
          }
        });
    });
  }

  abortQuery(): void {
    if (this.pendingRequest) {
      this.pendingRequest.abort(ABORT_ERROR);
    }
  }
}
