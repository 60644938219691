import { createContext, useState } from "react";
import PropTypes from "prop-types";

function createState(initialState, actionHandlers) {
  const context = createContext({
    state: initialState,
    actions: actionHandlers,
  });

  const Provider = ({ children }) => {
    const [state, setState] = useState(initialState);

    const actions = Object.entries(actionHandlers).reduce(
      (acc, [actionName, action]) => ({
        ...acc,
        [actionName]: (payload) => setState(action(state, payload)),
      }),
      {},
    );

    return (
      <context.Provider value={{ state, actions }}>{children}</context.Provider>
    );
  };

  Provider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return { Provider, context };
}

export default createState;
