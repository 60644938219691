import { FunctionComponent } from "react";

import Empty from "../empty";
import { intercomMessage } from "../../util/intercom";
import PageMeta from "../PageMeta";

const fireMessage = (): void => intercomMessage("Help! Something is broken.");

const Error: FunctionComponent = () => (
  <Empty emoji="🙈">
    <PageMeta title="Error" />
    <p>
      Ooops, something went wrong. Our team is on it.{" "}
      <a href="#" onClick={fireMessage}>
        {" "}
        Is there anything we could help with until it’s fixed?{" "}
      </a>
    </p>
  </Empty>
);

export default Error;
