import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const useInputSearchCommand = (
  defaultValue = "",
): [search: string, setSearch: (value: string) => void] => {
  const [search, setSearch] = useState(defaultValue);
  const history = useHistory();

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        history.push(`/search/?query=${search}`);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [history, search]);

  return [search, setSearch];
};
