import { useOSKeyCommand } from "../../../hooks/useOSKeyCommand";
import { TextButton } from "@learnerbly/web-components";

import styles from "./styles.scss";

interface AdvancedSearchButtonProps {
  onClick: () => void;
}

export const AdvancedSearchButton = (
  props: AdvancedSearchButtonProps,
): JSX.Element => {
  const [keyCommand] = useOSKeyCommand();

  return (
    <div className={styles.advancedSearchButtonContainer}>
      <TextButton onClick={props.onClick}>Advanced Search</TextButton>

      <div className={styles.commandFooterKeys}>
        <kbd>{keyCommand}</kbd>
        <kbd>↵</kbd>
      </div>
    </div>
  );
};
