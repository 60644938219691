import numeral from "numeral";
import moment from "moment-timezone";
import compact from "lodash/compact";

export const formatDate = (date: moment.MomentInput): string =>
  moment(date).format("DD/MM/YYYY");

export const formatTime = (date: moment.MomentInput): string =>
  moment(date).format("HH:mm");

export const formatText = (text: string, length: number): string =>
  text.length > length ? `${text.slice(0, length).trim()}...` : text;

export const formatNumber = (number: number): string => {
  return number && number > 1000
    ? numeral(number).format("0.0a")
    : numeral(number).format("0a");
};

export const addCurrency = (
  value: number,
  currency: Intl.NumberFormatOptions["currency"] | null,
  locale?: string | Array<string>,
  minimumFractionDigits = 2,
): string => {
  // Safari does not support "narrowSymbol".
  try {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency === null ? undefined : currency,
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits,
    }).format(value);
  } catch (error) {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency === null ? undefined : currency,
      currencyDisplay: "symbol",
      minimumFractionDigits,
    }).format(value);
  }
};

export const formatCurrencyValue = (
  value: number,
  currency: Intl.NumberFormatOptions["currency"] | null,
  locale?: string | Array<string>,
): string => {
  return addCurrency(value / 100, currency, locale);
};

export const shortCurrencyValue = (
  value: number,
  currency: Intl.NumberFormatOptions["currency"] | null,
  locale?: string | Array<string>,
): string => {
  return addCurrency(Math.ceil(value / 100), currency, locale, 0);
};

export const formatLinkWithProtocol = (url: string): string => {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `https://${url}`;
  }

  return url;
};

export const apostrophise = (string: string): string =>
  string.endsWith("s") ? `${string}’` : `${string}’s`;

export const readableList = (items: Array<string>): string => {
  return items.reduce(
    (output: string, item: string, index: number, array: Array<string>) => {
      if (!output) {
        return item;
      }

      if (array.length - 1 === index) {
        return `${output} and ${item}`;
      }

      return `${output}, ${item}`;
    },
  );
};

export const renderCurrencySymbol = (
  currency: Intl.NumberFormatOptions["currency"],
): string | undefined => {
  // Safari does not support "narrowSymbol".
  let formatted;
  try {
    formatted = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency,
      currencyDisplay: "narrowSymbol",
    }).formatToParts();
  } catch (error) {
    formatted = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency,
      currencyDisplay: "symbol",
    }).formatToParts();
  }

  const currencyFormat = formatted.find(({ type }) => type === "currency");

  if (!currencyFormat) {
    return currency;
  }

  return currencyFormat.value;
};

export const calculateBudget = (
  fullBudgetText: string,
  personStartDate: string | number | Date,
  selectedBudgetPeriod: {
    id?: string;
    endDate: string;
    name?: string;
    startDate: string;
  },
  isProRata: { label?: string; value: boolean },
): number | undefined => {
  if (!selectedBudgetPeriod) {
    return;
  }

  if (!fullBudgetText) {
    return;
  }

  const fullBudget = parseInt(fullBudgetText, 10);

  if (!isProRata.value) {
    return fullBudget * 100;
  }

  const personStart = new Date(personStartDate);
  const budgetStart = new Date(selectedBudgetPeriod.startDate);
  const budgetEnd = new Date(selectedBudgetPeriod.endDate);

  if (personStart.getTime() < budgetStart.getTime()) {
    return fullBudget * 100;
  }

  const totalBudgetDays = moment(budgetEnd).diff(moment(budgetStart), "days");
  const budgetPerDay = fullBudget / totalBudgetDays;
  const remainingBudgetDays = moment(budgetEnd).diff(
    moment(personStart),
    "days",
  );

  return Math.round(budgetPerDay * remainingBudgetDays * 100);
};

export const getEmojiFlagForCountry = (countryCode: string): string => {
  // 'Rest of the World' content region
  if (countryCode === "QQ") {
    return "🌏";
  }

  const offset = 127397;

  const flag =
    String.fromCodePoint((countryCode.codePointAt(0) || 0) + offset) +
    String.fromCodePoint((countryCode.codePointAt(1) || 0) + offset);

  return flag;
};

interface Datetime {
  date: string;
  time: string;
}

export const getLocalizedDateFields = (
  startTimeStamp: moment.MomentInput,
  endTimeStamp: moment.MomentInput,
  timezone: string,
  showDateAbbr = false,
): Datetime => {
  const startDate = moment(moment(startTimeStamp).format("YYYY-MM-DD HH:mm"))
    .tz(timezone)
    .format("DD MMM YY");
  const endDate =
    endTimeStamp &&
    moment(moment(endTimeStamp).format("YYYY-MM-DD HH:mm"))
      .tz(timezone)
      .format("DD MMM YY");

  const showEndDate =
    endTimeStamp && moment(endTimeStamp).diff(startTimeStamp, "days") >= 1;
  const abbr = moment(startTimeStamp).tz(timezone).format("z");
  const timeFrom = moment(moment(startTimeStamp).format("YYYY-MM-DD HH:mm"))
    .tz(timezone)
    .format("HH:mm");
  const timeTo =
    endTimeStamp &&
    moment(moment(endTimeStamp).format("YYYY-MM-DD HH:mm"))
      .tz(timezone)
      .format("HH:mm");

  return {
    date: compact([
      startDate,
      showEndDate && `- ${endDate}`,
      showDateAbbr && abbr,
    ]).join(" "),
    time: compact([timeFrom, timeTo && `- ${timeTo}`, abbr]).join(" "),
  };
};
