// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S0qeF7gIc2YCbWiN9hwt{position:fixed;top:5rem;z-index:1100}\n", "",{"version":3,"sources":["webpack://./src/shared/components/alert/styles.scss"],"names":[],"mappings":"AAAA,sBACE,cAAe,CACf,QAAS,CACT,YAAa","sourcesContent":[".container {\n  position: fixed;\n  top: 5rem;\n  z-index: 1100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "S0qeF7gIc2YCbWiN9hwt"
};
export default ___CSS_LOADER_EXPORT___;
