import { ResourceProvider } from "./ResourceProvider";
import { CommandResource } from "../types";

export class ResourceProviderDecorator implements ResourceProvider {
  private timerId: ReturnType<typeof setTimeout> | undefined = undefined;
  public loading = false;

  constructor(
    private provider: ResourceProvider,
    private setLoading: (value: boolean) => void,
  ) {}

  async fetch(query: string): Promise<Array<CommandResource>> {
    try {
      this.setLoading(true);
      return await this.provider.query(query);
    } finally {
      this.setLoading(false);
    }
  }

  async query(
    query: string,
    debounce: number,
  ): Promise<Array<CommandResource>> {
    clearTimeout(this.timerId);

    return new Promise((resolve) => {
      this.timerId = setTimeout(() => resolve(this.fetch(query)), debounce);
    });
  }

  abortQuery(): void {
    clearTimeout(this.timerId);
    this.provider.abortQuery();
  }
}
