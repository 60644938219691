import { FunctionComponent, useContext } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "src/app-context";

import ButtonLegacy from "src/shared/components/button-legacy";

import { hasRequestAnythingOption } from "src/shared/util/user-constraints";

interface Props {
  trackClick: (title: string) => void;
}

const OffPlatformResourceButton: FunctionComponent<Props> = ({
  trackClick,
}) => {
  const { currentUser } = useContext(AppContext);

  if (hasRequestAnythingOption(currentUser)) {
    return (
      <Link
        to="/request/resource-not-on-learnerbly/"
        onClick={() => trackClick("Resource not on Learnerbly")}
      >
        <ButtonLegacy smallBlue>Resource not on Learnerbly?</ButtonLegacy>
      </Link>
    );
  }

  return null;
};

export default OffPlatformResourceButton;
