import { CommandDialog } from "./components/CommandDialog";
import {
  SearchCommandContext,
  SearchCommandContextProvider,
} from "./contexts/SearchCommandContext";
import {
  ForwardedRef,
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
} from "react";

const SearchCommand = (
  props: Record<string, unknown>,
  ref: ForwardedRef<{ open: () => void }>,
): JSX.Element => {
  const containerElement = useRef(null);
  const searchCommandContext = useContext(SearchCommandContext);

  useImperativeHandle(ref, () => ({
    open: () => searchCommandContext.setOpen(true),
  }));

  return (
    <div ref={containerElement}>
      <CommandDialog
        {...props}
        container={containerElement.current ?? undefined}
      />
    </div>
  );
};

const SearchCommandWithRef = forwardRef(SearchCommand);

const SearchCommandWrapper = (
  props: Record<string, unknown>,
  ref: ForwardedRef<{ open: () => void }>,
): JSX.Element => {
  return (
    <SearchCommandContextProvider>
      <SearchCommandWithRef ref={ref} {...props} />
    </SearchCommandContextProvider>
  );
};

export default forwardRef(SearchCommandWrapper);
