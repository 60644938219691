import { getFocusName } from "../../../growth-plan/helpers";
import { initializeSegment, identify, track } from "../../util/segment";
import {
  isApprover,
  isOrgAdmin,
  isAdmin,
  isInPotsOrganisation,
} from "../../util/user-constraints";

initializeSegment();

const trackProductListView = (
  list_id,
  category,
  resources,
  startTrackPosition,
) =>
  track(
    "Product List Viewed",
    {
      list_id,
      category,
      products: resources.map((resource, index) => {
        const { cheapestProduct } = resource;
        const image = resource.image2x1 || resource.image;

        return {
          position: startTrackPosition + index,
          product_id: resource.id,
          sku: resource.id,
          name: resource.title,
          image_url: image,
          category: resource.type.name,
          ...(cheapestProduct && { price: cheapestProduct.price / 100 }),
        };
      }),
    },
    {
      integrations: {
        All: false,
        "Amazon Personalize": true,
        "Amazon S3": true,
      },
    },
  );

export const identifyUser = (currentUser) => {
  const {
    id,
    organisation,
    firstName,
    lastName,
    createdAt,
    activeIndividualBudget,
    focuses,
    pdpFocuses,
    featureToggles,
    goals,
    groups,
    isManager,
    jobTitle,
    jobFamily,
    jobFamilyOther,
    jobLevel,
    jobLevelOther,
  } = currentUser;

  identify(id, {
    hasSignedUp: true,
    company: {
      name: organisation.name,
      id: organisation.id,
    },
    organisationId: organisation.id,
    organisationName: organisation.name,
    createdAt,
    firstName,
    lastName,
    ...(activeIndividualBudget && {
      "activeIndividualBudget/remaining": activeIndividualBudget.remaining,
      "activeIndividualBudget/spent": activeIndividualBudget.spent,
      "activeIndividualBudget/total": activeIndividualBudget.total,
    }),
    focuses: focuses && focuses.map(({ subject }) => subject.name),
    pdpFocuses: pdpFocuses && pdpFocuses.map(({ id }) => getFocusName(id)),
    featureToggles,
    activeIndividualBudget,
    isManager,
    isOrgAdmin: isOrgAdmin(currentUser),
    isApprover: isApprover(currentUser),
    potsCount: isInPotsOrganisation(currentUser)
      ? currentUser.pots?.length ?? 0
      : null,
    isInPotsOrganisation: isInPotsOrganisation(currentUser),
    isAdmin: isAdmin(currentUser),
    isTestUser: organisation.testClient,
    hasStartedPDP: currentUser.startedPDP,
    openGoalCount: goals
      ? goals.filter(({ status }) => status === "OPEN").length
      : 0,
    doneGoalCount: goals
      ? goals.filter(({ status }) => status === "DONE").length
      : 0,
    deletedGoalCount: goals
      ? goals.filter(({ status }) => status === "DELETED").length
      : 0,
    groups: groups ? groups.map(({ name }) => name) : "No groups",
    jobTitle,
    jobFamily,
    jobFamilyOther,
    jobLevel,
    jobLevelOther,
  });
};

export { trackProductListView };
