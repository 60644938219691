// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R3G3TXYUZK64HEKWN1cD{border-radius:50%;background-color:#e8f3f9;justify-content:center;align-items:center;overflow:hidden;display:flex;width:100%;height:100%;position:absolute;top:0;left:0;color:#92a5bb}.G2PoMhha7A5YfCmPuH19{width:100%}\n", "",{"version":3,"sources":["webpack://./src/shared/components/user-image/styles.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAkB,CAClB,wBAAyB,CACzB,sBAAuB,CACvB,kBAAmB,CACnB,eAAgB,CAChB,YAAa,CACb,UAAW,CACX,WAAY,CACZ,iBAAkB,CAClB,KAAM,CACN,MAAO,CACP,aAAc,CACf,sBAGC,UAAW","sourcesContent":["@import \"src/shared/styles/variables\";\n\n.container {\n  border-radius: 50%;\n  background-color: #e8f3f9;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n  display: flex;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  color: #92a5bb;\n}\n\n.image {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "R3G3TXYUZK64HEKWN1cD",
	"image": "G2PoMhha7A5YfCmPuH19"
};
export default ___CSS_LOADER_EXPORT___;
