import { useEffect, useContext } from "react";

import { page } from "../util/segment";
import { AppContext } from "../../app-context";
import { useLocation } from "react-router-dom";

import { identifyUser } from "../components/analytics";

import { TrackingContext } from "../components/tracking/tracking";

const usePageView = (
  name: string,
  featureToggles?: Record<string, string | boolean>,
): void => {
  const { currentUser } = useContext(AppContext);
  const { contextualTrackingData } = useContext(TrackingContext);

  const location = useLocation();

  useEffect(() => {
    const trackPageView = (url: string) =>
      page(name, { ...contextualTrackingData, url, featureToggles });
    const currentPage = location.pathname + location.search;

    if (currentUser) {
      identifyUser(currentUser);

      trackPageView(currentPage);
    }
  }, [
    location.pathname,
    location.search,
    currentUser,
    name,
    featureToggles,
    contextualTrackingData,
  ]);
};

export default usePageView;
