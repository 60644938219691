import { FunctionComponent, useContext } from "react";
import { Label } from "@learnerbly/web-components";
import { Link } from "react-router-dom";
import { AppContext } from "src/app-context";
import { hasResourceTypeView } from "src/shared/util/user-constraints";
import { track } from "src/shared/util/segment";

const resourcePages: Array<{ name: string; url: string }> = [
  { name: "Article", url: "/articles/" },
  { name: "Book", url: "/books/" },
  { name: "Coach", url: "/coaches/" },
  { name: "Conference", url: "/conferences/" },
  { name: "Course", url: "/courses/" },
  { name: "Meetup", url: "/meetups/" },
  { name: "Online course", url: "/online-courses/" },
  { name: "Other", url: "/other/" },
  { name: "Podcast", url: "/podcasts/" },
  { name: "Video", url: "/videos/" },
  { name: "Playlist", url: "/playlists/" },
];

const getResourceTypeLink = (type: string): string => {
  const resource = resourcePages.find(
    (resourcePage) => resourcePage.name === type,
  );

  return resource?.url || "";
};

const LinkLabel: FunctionComponent<{ type: string }> = ({ type }) => {
  const { currentUser } = useContext(AppContext);

  if (hasResourceTypeView(currentUser)) {
    return (
      <Link
        to={getResourceTypeLink(type)}
        onClick={(event) => {
          event.stopPropagation();
          track("Resource Label Clicked", { resourceType: type });
        }}
      >
        <Label type={type} />
      </Link>
    );
  }

  return <Label type={type} />;
};

export default LinkLabel;
