import kebabCase from "lodash/kebabCase";
import { FeaturesReady } from "@growthbook/growthbook-react";
import { FunctionComponent } from "react";
import { useQuery } from "@apollo/client";

import Empty from "../empty";
import Loading from "../loading";

import whoamiQuery from "./whoami.ts.graphql";

import { whoami as TWhoami } from "./__generated__/whoami";

import { AppContextWrapper } from "src/app-context";
import { GrowthBook } from "../growthBook/growthBook";

// This is required because the return type of `FeaturesReady` from Growthbook is incorrect
type FeatureReadyProps = Parameters<typeof FeaturesReady>[0];
const FixedFeaturesReady =
  FeaturesReady as FunctionComponent<FeatureReadyProps>;

const Authorizer: FunctionComponent = () => {
  const { data, loading, error } = useQuery<TWhoami>(whoamiQuery);

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    if (error?.message && error.message.includes("Maintenance")) {
      return (
        <Empty emoji="👩‍🏭">
          <p>
            Ouch — Learnerbly is currently unavailable. We will be back shortly.
          </p>
        </Empty>
      );
    }

    return (
      <Empty emoji="⚡️">
        <p>Ouch — Can’t connect to Learnerbly. Please try again.</p>
      </Empty>
    );
  }

  return (
    <GrowthBook currentUser={data.user}>
      <FixedFeaturesReady timeout={500} fallback={<Loading />}>
        <AppContextWrapper
          user={
            data.user && {
              orgPageUrl: kebabCase(data.user.organisation.name),
              ...data.user,
            }
          }
        />
      </FixedFeaturesReady>
    </GrowthBook>
  );
};

export default Authorizer;
