import { useHistory } from "react-router-dom";
import { FunctionComponent, useEffect, useRef } from "react";
import { autocomplete } from "@algolia/autocomplete-js";
import { RouterState } from "src/AlgoliaSearch/AlgoliaSearch";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import { OnSubmitParams, BaseItem } from "@algolia/autocomplete-core";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { track } from "src/shared/util/segment";

type SearchBarWithSuggestionsProps = {
  onSearch?: () => void;
};

const SearchBarWithSuggestions: FunctionComponent<
  SearchBarWithSuggestionsProps
> = ({ onSearch }) => {
  const environment = process.env.STAGE as string;
  const indexName = `${environment}_resources_query_suggestions`;

  const containerRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const queryFromLocationState =
    (history.location.state as RouterState)?.query || "";

  const initialState = { query: queryFromLocationState };

  const searchClient = algoliasearch(
    process.env.ALGOLIA_APP_ID || "",
    process.env.ALGOLIA_SEARCH_QUERY_SUGGESTIONS_KEY || "",
  );

  const makeSearch = (queryTerm: string) => {
    track("Products Searched - From Query Suggestions", {
      queryFromLocationState,
      context: "Header",
    });

    history.push("/search/?query=" + queryTerm, { query: queryTerm });
    if (onSearch) {
      onSearch();
    }
  };

  const querySuggestionsPlugin = createQuerySuggestionsPlugin({
    searchClient,
    indexName,
    categoryAttribute: ["instant_search"],
    getSearchParams() {
      return {
        hitsPerPage: 10,
      };
    },
    transformSource({ source }) {
      return {
        ...source,
        onSelect(itemObj) {
          makeSearch(itemObj.item.query);
        },
      };
    },
  });

  useEffect(() => {
    // prevent re-renders
    if (containerRef.current && !containerRef.current.hasChildNodes()) {
      autocomplete({
        container: containerRef.current,
        placeholder: "Search...",
        openOnFocus: true,
        insights: true,
        panelPlacement: "start",
        plugins: [querySuggestionsPlugin],
        initialState,
        enterKeyHint: "go",
        onSubmit: (params: OnSubmitParams<BaseItem>) =>
          makeSearch(params.state.query),
      });
    }
  }, []); // render only once on load

  return (
    <div
      ref={containerRef}
      data-testid="search-bar-with-suggestions-container"
    />
  );
};

export default SearchBarWithSuggestions;
