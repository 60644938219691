const gocardless = "b67bb052-4712-4bd1-babb-af5b7d45aec5";
const learnerbly = "fc45ce0d-1f45-411f-a8bb-724404f525a4";
const meta = "1eb6c1c5-90ac-4256-ae76-30251cad5a5b";
const motorway = "56e7fbe3-8c17-4147-9e08-e9083f968933";
const unmind = "bb07b438-8cb7-4ea4-9dbf-dcc63456ec23";

export const organisationsWithPrivacyToggle = [
  gocardless,
  learnerbly,
  meta,
  motorway,
  unmind,
];
