import { FunctionComponent } from "react";

import styles from "./styles.scss";

export const SkipToMainContent: FunctionComponent = () => {
  return (
    <a className={styles.link} href="#mainContent">
      Skip to main content
    </a>
  );
};
